.header-bottom {
  display: flex;
  justify-content: flex-start;
  border-bottom: 1px solid #ddd;
  width: 100%;
  align-items: center;
  background-color: #ffffff;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropbtn {
  background-color: #ee1925;
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 240px;
  font-weight: bold;
  margin-left: 10px;
}

.dropbtn .fa-bars {
  margin-right: auto;
}

.dropbtn .fa-chevron-down {
  margin-left: auto;
}

.dropdown-content {
  display: none;
  position: absolute;
  left: 0;
  background-color: #f9f9f9;
  min-width: 240px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-left: 10px;
}

.dropdown-content a {
  color: black;
  padding: 6px 8px;
  text-decoration: none;
  display: flex;
  align-items: center;
  height: 30px;
}

.dropdown-content a:hover {
  background-color: #ee1925;
  color: #ffffff;
}

.dropdown:hover .dropdown-content,
.dropbtn.active + .dropdown-content {
  display: block;
}

.menu-items {
  display: flex;
  align-items: end;
  background-color: #ffffff;
  justify-content: space-between;
}

.menu-items.right {
  background-color: #ffffff;
  margin-left: auto;
  text-align: right;
}

.menu-items.left {
  background-color: #ffffff;
  width: 20%;
}

.home-button {
  color: #000000;
  font-size: 12px;
  text-decoration: none;
  padding: 5px 10px;
  transition: color 0.3s;
  font-weight: bold;
  background-color: #ffffff;
}

.home-button:first-of-type {
  margin-left: 0;
}

.home-button:hover {
  color: #ee1925;
}

.red-text {
  color: #84001c;
}

.category-item {
  position: relative;
  padding: 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}

.category-item:hover {
  background-color: #ee1925;
  color: #ffffff;
}

.subcategory-menu {
  display: none;
  position: absolute;
  left: 100%;
  top: 0;
  background-color: #f9f9f9;
  min-width: 200px;
  z-index: 2;
  white-space: nowrap;
}

.category-item:hover .subcategory-menu {
  display: block;
}

.subcategory-item {
  padding: 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.subcategory-item:hover {
  background-color: #ee1925;
  color: #ffffff;
}

.sub-subcategory-menu {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  z-index: 3;
  left: 100%;
  top: 0;
  white-space: nowrap;
}

.subcategory-item:hover .sub-subcategory-menu {
  display: block;
}

.sub-subcategory-menu a {
  padding: 0 10px;
  height: 30px;
  display: flex;
  align-items: center;
  background-color: #ffffff;
  border-bottom: 1px solid #ddd;
}

.sub-subcategory-menu a:hover {
  background-color: #ee1925;
  color: #ffffff;
}

/* Mobile styles */

@media (max-width: 768px) {
  .header-bottom {
    flex-direction: column-reverse;
  }

  .dropdown {
    /* display: none; */
    width: 60%;
    align-self: flex-start;
  }

  .dropdown-content {
    min-width: 93%;
  }

  .dropbtn {
    min-width: 93%;
  }

  .mobile-menu-button {
    background-color: #ee1925;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .mobile-menu-button .fa-bars {
    font-size: 20px;
  }

  .dropdown-content.show-mobile {
    display: block; /* Show mobile menu when toggled */
  }
  .menu-items.left {
    display: none;
  }

  .menu-items.right a {
    font-size: 12px;
    text-align: center;
  }

  /* .menu-items {
    display: none;
  } */
}

.category-menu-icon {
  width: 24px; /* Set the width of the image */
  height: 24px; /* Set the height of the image */
  border-radius: 50%; /* Apply rounded corners */
  margin-right: 8px; /* Add some space between the image and the text */
  object-fit: cover; /* Ensure the image covers the space without distortion */
}
