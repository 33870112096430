/* Container for the whole section */
.account-container {
  background-color: #f0f0f0;
  /* Light gray background */
  padding: 50px 0;
  text-align: center;
}

/* Breadcrumb styling */
.breadcrumb-container {
  margin-bottom: 20px;
}

.breadcrumb {
  display: inline-flex;
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 16px;
  color: #333;
}

.breadcrumb li {
  margin: 0 5px;
}

.breadcrumb li a {
  text-decoration: none;
  color: #333;
}

.breadcrumb li a:hover {
  text-decoration: underline;
}

/* Account title styling */
.account-title {
  font-size: 36px;
  text-transform: uppercase;
  color: #333;
  margin-top: 10px;
}

/* Forms styling */
.forms-container {
  display: flex;
  justify-content: center; /* Centers content horizontally */
  align-items: center; /* Centers content vertically */
  width: 100%;
  padding: 20px; /* Adds spacing around the forms */
}

.form-column {
  background-color: #fff;
  padding: 30px;
  width: 100%;
  max-width: 500px; /* Controls max width for better readability */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  border-radius: 8px; /* Adds rounded corners */
}

.form-title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

form label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  text-align: left;
}

form input {
  width: 100%;
  padding: 12px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Container for first name and last name */
.name-container {
  display: flex;
  justify-content: space-between;
}

.name-container .input-group {
  flex-basis: 48%;
}

/* Container for email and phone */
.contact-container {
  display: flex;
  justify-content: space-between;
}

.contact-container .input-group {
  flex-basis: 48%;
}

button[type="submit"] {
  width: 100%;
  padding: 14px;
  background-color: #ee1925;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

button[type="submit"]:hover {
  background-color: #c1171e;
}

.notification {
  background-color: #d4edda;
  color: #155724;
  padding: 10px;
  margin: 20px 0;
  border: 1px solid #c3e6cb;
  border-radius: 4px;
  text-align: center;
}

.forgot-button {
  background: none;
  color: #007bff;
  border: none;
  text-decoration: underline;
  font-size: 14px;
  cursor: pointer;
  padding: 0;
  margin-top: 10px;
}

.forgot-button:hover {
  /* color: white; */
  text-decoration: none;
}

.forgot-button:focus {
  outline: none;
  color: #0056b3;
  text-decoration: none;
}


.account-container {
  text-align: center;
  padding: 20px;
}

.account-title {
  font-size: 24px;
  margin-bottom: 10px;
}

.account-notification {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px;
  margin-bottom: 15px;
  border-radius: 5px;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

.account-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}

.account-form-box {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  width: 350px;
  text-align: center;
}

.account-form-title {
  margin-bottom: 15px;
}

.account-form-box input {
  width: 100%;
  padding: 10px;
  margin: 5px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.account-form-box button {
  width: 100%;
  padding: 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.account-toggle-button {
  background: none;
  border: none;
  border-radius: 5px;
  color: #007bff;
  cursor: pointer;
  font-size: 14px;
}

.toggle-button {
  background: none;
  border: none;
  color: #007bff;
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}

.toggle-button:hover {
  color: #0056b3;
}