.orders-container {
  padding: 20px;
}

.orders-title {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
  text-decoration: underline;
}

.orders-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.table-container {
  height: 400px;
  overflow-y: auto;
}

.orders-table th,
.orders-table td {
  padding: 10px;
  text-align: left;
  border: 1px solid #ddd;
}

.orders-table th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.orders-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.orders-table tr:hover {
  background-color: #f1f1f1;
}

.orders-table ul {
  list-style-type: none;
  padding-left: 0;
  margin: 0;
}

.orders-table ul li {
  font-size: 0.9rem;
}

.no-orders {
  font-size: 1.2rem;
  color: #555;
  text-align: center;
}

.loading,
.error {
  text-align: center;
  font-size: 1.5rem;
  color: #ff6347;
}
