.checkout-container {
  display: flex;
  justify-content: center;
  gap: 200px;
  padding: 20px 40px;
  flex-wrap: wrap;
}

.billing-details {
  width: 100%;
  max-width: 400px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
}

.order-summary {
  width: 100%;
  max-width: 650px;
  margin-left: 20px;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 8px;
}

.order-item-details {
  display: flex;
  align-items: center;
}

.order-item-details img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.order-total {
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  font-size: 1.2em;
}

.place-order-button {
  width: 100%;
  padding: 10px;
  background-color: #2252d1;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
}

.place-order-button:hover {
  background-color: #0056b3;
}

.checkout-container button {
  padding: 10px 20px;
  background-color: #2252d1;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.checkout-container button:hover {
  background-color: #0056b3;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .checkout-container {
    flex-direction: column;
    align-items: center;
  }

  .billing-details,
  .order-summary {
    width: 90%;
  }

  .order-summary {
    margin-left: 0;
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .form-group input,
  .place-order-button,
  button {
    font-size: 14px;
    padding: 12px;
  }

  .order-summary .order-item {
    flex-direction: column;
    align-items: flex-start;
  }
}
