.product-slider-wrapper {
  width: 100%;
  margin: 0 auto;
  padding: 10px 50px;
  overflow: hidden; /* Hide overflow to ensure slider fits within its container */
}

.product-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between; /* Push the button to the bottom */
  width: 170px;
  height: 400px;
  padding: 10px;
  margin: 10px 5px; /* Ensure a 5px margin on left and right */
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease;
  text-align: center;
  position: relative; /* Ensure that children can be positioned relative to this container */
}

.product-item img {
  width: 100%;
  height: auto;
  max-height: 190px;
  object-fit: contain; /* Changed from cover to ensure the image does not overflow beyond the div */
  margin-bottom: 10px;
  border-radius: 4px;
}

.product-title {
  font-size: 14px;
  font-weight: normal;
  margin: 2px 0;
  max-height: 50px;
  overflow: hidden; /* Hide overflow text */
  color: black;
}

.product-description {
  font-size: 14px;
  color: #555;
  margin-bottom: 10px;
  max-height: 50px; /* Limit description height */
  overflow: hidden; /* Hide overflow text */
  width: 160px;
}

.add-to-cart-button {
  width: 70%;
  padding: 8px 0;
  font-size: 14px;
  font-weight: bold;
  color: white;
  background-color: #e74c3c;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  position: absolute; /* Position the button at the bottom of the container */
  bottom: 10px; /* Space from the bottom of the container */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%); /* Offset by half of its width */
}

.add-to-cart-button:hover {
  background-color: #c0392b;
}

.slick-slide {
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  padding: 0 5px; /* Ensure padding between slides */
}

.slick-list {
  margin: 0 -5px; /* Compensate for padding in slick-slide to ensure full width */
}
