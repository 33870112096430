.category-slider-container {
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  background-color: #000; /* Optional background to visualize */
}

.category-slider {
  display: flex;
  overflow-x: hidden;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  width: 100%; /* Ensure it spans the entire width */
  height: 100%; /* Ensure it spans the entire height */
}

.category-slider-item {
  flex: 0 0 auto;
  height: 250px;
  margin: 0 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f8f8f8;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  padding: 10px;
}

.banner-ad-component {
  display: flex;
  flex: 0 0 100%; /* Take full width of the parent */
  max-height: 250px;
  justify-content: space-between;
  overflow: hidden;
  background-color: #030303; /* Background to test */
}

.banner-content {
  display: flex;
  width: 100%;
  height: 100%; /* Ensure it spans the full height of the banner */
}

.banner-text {
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  color: white;
  text-align: center;
}

.banner-text h2 {
  font-size: 24px;
  margin: 0;
  color: white;
}

.banner-text p {
  font-size: 16px;
  margin: 10px 0;
}

.banner-button {
  background-color: #ee1925;
  border: none;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 25px;
  width: 35%;
}

.banner-image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.banner-image img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.scroll-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 20px;
  z-index: 2;
}

.scroll-button.left {
  left: 10px;
}

.scroll-button.right {
  right: 10px;
}

.scroll-button:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

@media (max-width: 768px) {
  .banner-button {
    font-size: 12px;
    border-radius: 10px;
    padding: 10px;
    width: 50%;
  }
}
