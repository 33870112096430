.special-order-page {
  max-width: 800px;
  margin: 10px auto;
  padding: 20px 30px;
}

.special-order-description {
  padding: 30px 50px;
}

.special-order-description p {
  color: black;
  font-size: 18px;
}

.special-order-page h1 {
  font-size: 36px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
  text-decoration: underline;
}

.special-order-notification {
  padding: 15px;
  background-color: #d4edda;
  color: #155724;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.special-order-container {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.special-order-form-group {
  margin-bottom: 15px;
}

.special-order-page label {
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.special-order-page input,
textarea,
select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.special-order-page textarea {
  height: 100px;
  resize: vertical;
}

.special-order-submit-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.special-order-submit-button:hover {
  background-color: #0056b3;
}

.special-order-page button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .special-order-page h1 {
    font-size: 24px;
    margin-bottom: 10px;
  }

  .special-order-description {
    padding: 20px;
  }
  .special-order-description p {
    font-size: 16px;
  }
}
