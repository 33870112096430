.footer-container {
  height: auto; /* Allow height to adjust based on content */
  background-color: black;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
  box-sizing: border-box;
}

.footer-top {
  display: flex;
}

.footer-left,
.footer-right {
  width: 100%;
  padding: 20px;
}

h3,
h4 {
  margin-top: 20px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  color: white;
  font-size: 18px;
}

.social-media-icons,
.download-icons {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.app-icon {
  width: 30px;
  height: auto;
}

.email-input {
  width: 60%;
  padding: 10px;
  margin-top: 10px;
  border: none;
  border-radius: 5px;
}

.phone-number {
  font-size: 24px;
  font-weight: 500;
  color: white;
}

.help-section {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.help-left {
  width: 25%;
}

.help-right {
  width: 75%;
}

.help-icon {
  font-size: 50px;
  color: white;
}

.icon-circle {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: maroon;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-right {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.footer-section {
  display: flex;
  flex-direction: column;
}

.footer-section h4 {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: bold;
  color: white;
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
}

.footer-section ul li {
  margin-bottom: 5px;
}

.footer-section ul li a {
  text-decoration: none;
  color: #928c8c;
}

.footer-section ul li a:hover {
  text-decoration: underline;
}

.footer-bottom {
  position: relative;
  padding: 20px;
  background-color: black;
  color: white;
  text-align: right;
  width: 100%;
  box-sizing: border-box;
  margin-right: 20px;
  margin-top: 20px;
}

@media (max-width: 1200px) {
  .footer-left {
    width: 50%;
  }

  .footer-right {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .footer-top {
    flex-direction: column;
  }
  .footer-container {
    flex-direction: column; /* Stack all content vertically on smaller screens */
  }

  .email-input {
    width: 70%;
  }

  .footer-left,
  .footer-right {
    width: 100%; /* Make both sections take full width */
    padding: 15px;
  }

  .social-media-icons,
  .download-icons {
    justify-content: center; /* Center social media and download icons */
    margin-top: 15px;
  }

  .footer-right {
    grid-template-columns: repeat(2, 1fr); /* Two columns on medium screens */
  }

  .footer-section h4 {
    font-size: 16px; /* Smaller font size for better readability on small screens */
  }

  .footer-bottom {
    text-align: center; /* Center the bottom text */
  }

  .help-right .phone-number {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .footer-left {
    padding: 10px;
  }

  .footer-right {
    padding: 10px;
  }

  .social-media-icons {
    justify-content: space-evenly; /* Space icons evenly */
    margin-top: 10px;
  }

  .download-icons {
    flex-direction: column; /* Stack download icons vertically */
    margin-top: 10px;
  }

  .footer-right {
    grid-template-columns: 1fr; /* Stack footer sections vertically on very small screens */
  }

  .footer-section h4 {
    font-size: 14px; /* Smaller font size on mobile screens */
  }

  .help-section {
    /* flex-direction: column;  */
    margin-top: 15px;
  }

  .icon-circle {
    width: 60px; /* Adjust the size of the icon circle */
    height: 60px;
  }

  .help-icon {
    font-size: 40px; /* Adjust the size of the help icon */
  }
}
