/* ProductFilters.css */
.filters-container {
    display: flex;
    justify-content: space-between; /* Space out title and badges */
    align-items: center;
    padding: 10px;
  }
  
  .filters-title {
    font-size: 18px;
    font-weight: normal;
    margin: 0;
    margin-left: 50px;
  }
  
  .filters-badges {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Space between badges */
    margin-right: 50px;
  }
  
  .filter-badge {
    display: flex;
    align-items: center;
    background-color: #ffffff;
    border-radius: 12px;
    padding: 5px 10px;
    border: 1px solid #ddd;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .filter-badge i {
    margin-right: 5px;
  }
  
  .filter-name {
    font-size: 14px;
  }
  
  .filter-badge:hover {
    background-color: #ececec;
  }
  