/* styles.css */

.layout {
    display: flex;
    flex-direction: row;
    gap: 20px;
    padding: 10px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .taxonomy-list-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 250px; /* Ensure the container doesn't exceed 250px */
  }
  
  .taxonomy-item {
    width: 250px; /* Fixed width */
    height: 55px; /* Fixed height */
    margin: 2px 0;
    padding: 5px 10px; /* Adjusted padding for better alignment */
    border: 1px solid #ccc; /* Border color */
    border-radius: 40px; /* Rounded corners */
    background-color: #f9f9f9; /* Background color */
    display: flex;
    align-items: center;
    transition: box-shadow 0.3s ease; /* Transition for hover effect */
    overflow: hidden; /* Hide any overflow */
  }
  
  .taxonomy-item:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect on hover */
  }
  
  .taxonomy-link {
    display: flex;
    align-items: center;
    width: 100%; /* Make link span the full width */
    text-decoration: none; /* Remove underline */
    color: inherit; /* Inherit text color */
  }
  
  .taxonomy-thumb {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    background-color: #e0e0e0; /* Grey background */
    border-radius: 50%; /* Circular border */
    overflow: hidden; /* Ensure image doesn't overflow */
    margin-right: 10px; /* Add space between the image and title */
    flex-shrink: 0; /* Prevent shrinking */
  }
  
  .taxonomy-thumb img {
    max-width: 80%; /* Ensure image fits well within the circle */
    max-height: 80%; /* Ensure image fits well within the circle */
    border-radius: 50%; /* Make image circular */
  }
  
  .taxonomy-title {
    flex-grow: 1; /* Allow the title to take up remaining space */
    text-align: center; /* Center the title text */
    font-weight: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .taxonomy-count {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    background-color: #CEE8FF;
    border-radius: 50%; 
    color: rgb(104, 80, 200);
    font-weight: normal;
    margin-left: auto; /* Push count to the far right */
    flex-shrink: 0; /* Prevent shrinking */
  }
  
  .slider-wrapper {
    width: 100%;
    margin: 0 auto;
    padding: 10px 10px;
    overflow: hidden; /* Hide overflow to ensure slider fits within its container */
  }
  
  
  
  .slick-slide {
    display: flex;
    justify-content: center;
    padding: 0 5px;
  }
  
  .slick-list {
    padding: 0 5px;
  }
  
  .columnBanner {
    width: 100%;
    padding: 10px; /* Adjust padding as needed */
    background-color: #FEF6E9; /* Background color for the column */
    border-radius: 8px; /* Rounded corners */
    position: relative; /* Position relative to use absolute positioning for children */
   

  }
  
  .content {
    display: flex;
    align-items: center; /* Center items vertically */
    justify-content: space-between; /* Space between badges, image, and button */
    height: 100%; /* Full height to center image vertically */
  }
  
  .image-container {
    display: flex;
    justify-content: center; /* Center image horizontally */
    align-items: center; /* Center image vertically */
    max-width: 200px; /* Optional: Limit max width of the image container */
    max-height: 200px; /* Optional: Limit max height of the image container */
    width: 100%; /* Full width to ensure it fits within container */
    height: 100%; /* Full height to ensure it fits within container */
    overflow: hidden; /* Ensure content does not overflow */
  }
  
  .image-container img {
    max-width: 100%; /* Make sure image fits within container width */
    max-height: 100%; /* Make sure image fits within container height */
    object-fit: cover; /* Cover the container without stretching */
    border-radius: 8px; /* Rounded corners for the image */
  }

  .banner-details-link {
    background-color: #F4EAD5; /* Button background color */
    color: black; /* Button text color */
    padding: 8px 12px; /* Button padding */
    border-radius: 10px; /* Rounded corners for the button */
    text-decoration: none; /* Remove underline from the link */
    font-size: 14px; /* Adjust font size as needed */
    display: flex;
    align-items: center; /* Center text vertically */
  }

  .badges-container {
    display: flex;
    align-items: center; /* Center badges vertically */
  }
  
  .banner-badge {
    padding: 5px 10px; /* Adjust padding as needed */
    border-radius: 12px; /* Rounded corners for badges */
    font-size: 14px; /* Adjust font size as needed */
    font-weight: normal;
  }
  
  .product-sliders-container {
    display: flex;
    justify-content: space-between; /* Space between the sliders */
    padding: 10px; /* Optional: Add padding around the container */
  }
  
  .product-slider {
    flex: 1; /* Ensure each slider takes equal space */
    margin: 0 10px; /* Add margin between sliders */
    box-sizing: border-box; /* Include padding and border in the element’s total width and height */
  }
  
  