.main-about-div {
  display: flex;
  flex-direction: column;
}

.about-us {
  max-width: 1100px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-us h1 {
  text-align: center;
  font-size: 36px;
  color: #333;
  margin-bottom: 30px;
}

.about-us section {
  margin-bottom: 30px;
}

.about-us h2 {
  font-size: 28px;
  color: #ee1926; /* Brand color */
  margin-bottom: 15px;
}

.about-us p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.about-us ul {
  list-style-type: none;
  padding-left: 0;
}

.about-us ul li {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.about-us a {
  color: #ee1926; /* Brand color */
  text-decoration: none;
}

.about-us a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .about-us {
    margin: 20px;
    padding: 15px;
  }

  .about-us h1 {
    font-size: 28px;
  }

  .about-us h2 {
    font-size: 22px;
  }

  .about-us p,
  .about-us ul li {
    font-size: 14px;
  }
}
