.gift-card-page {
  max-width: 900px;
  margin: 30px auto;
  padding: 30px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
}

.gift-card-page h1 {
  font-size: 36px;
  color: #333;
  text-align: center;
  margin-bottom: 30px;
}

.gift-card-notification {
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
  background-color: #d4edda;
  color: #155724;
  font-size: 16px;
  font-weight: bold;
}

.gift-card-notification.error {
  background-color: #f8d7da;
  color: #721c24;
}

.gift-card-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.gift-card-purchase-section,
.gift-card-redeem-section {
  width: 45%;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gift-card-purchase-section h2,
.gift-card-redeem-section h2 {
  margin-bottom: 20px;
}

label {
  font-size: 14px;
  margin-bottom: 8px;
}

.gift-input,
select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.gift-button {
  padding: 10px 15px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.gift-button:hover {
  background-color: #0056b3;
}

.gift-card-custom-amount {
  margin-top: 15px;
}

.gift-card-contact-method {
  margin-top: 15px;
}

.gift-card-transaction-history {
  margin-top: 40px;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.gift-card-transaction-history h3 {
  color: black;
}

.gift-card-transaction-history ul {
  list-style-type: decimal;
  padding: 0;
}

.gift-card-transaction-history li {
  padding: 10px 0;
  border-bottom: 1px solid #f0f0f0;
}

.gift-card-transaction-history li:last-child {
  border-bottom: none;
}

@media (max-width: 768px) {
  .gift-card-container {
    flex-direction: column;
  }

  .gift-card-purchase-section,
  .gift-card-redeem-section {
    width: 90%;
  }
}
