/* General Page Styling */
.contact-us-page {
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
  color: #333;
}

.contact-us-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-us-title {
  text-align: center;
  font-size: 32px;
  color: #333;
  margin-bottom: 10px;
}

.contact-us-description {
  text-align: center;
  font-size: 18px;
  color: #666;
  margin-bottom: 30px;
}

/* Contact Form Section */
.contact-us-content {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contact-form-container {
  flex: 1;
  min-width: 300px;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-group label {
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 14px;
}

.form-group input,
.form-group textarea {
  padding: 12px;
  border: 1px solid #ccc;
  border-radius: 6px;
  font-size: 14px;
  width: 100%;
  box-sizing: border-box;
}

.form-group input::placeholder,
.form-group textarea::placeholder {
  color: #aaa;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  background-color: #ee1926;
  color: #fff;
  padding: 14px;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
  text-align: center;
}

.submit-button:hover {
  background-color: #d4151f;
}

/* Form Status */
.form-status {
  margin-top: 20px;
  padding: 12px;
  background-color: #d4edda;
  color: #155724;
  border-radius: 6px;
  border: 1px solid #c3e6cb;
  text-align: center;
}

/* Contact Details Section */
.contact-details-container {
  flex: 1;
  min-width: 250px;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.contact-details-title {
  font-size: 24px;
  color: #333;
  margin-bottom: 15px;
}

.contact-details-list {
  list-style: none;
  padding-left: 0;
  font-size: 16px;
  color: #555;
}

.contact-details-list li {
  margin-bottom: 12px;
}

.contact-details-list strong {
  color: #333;
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-us-content {
    flex-direction: column;
    gap: 40px;
  }

  .contact-form-container,
  .contact-details-container {
    min-width: 100%;
  }
}
