.forgot-password-container {
  max-width: 500px;
  margin: 30px auto;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.forgot-password-container h2 {
  font-size: 28px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.forgot-password-notification {
  padding: 15px;
  background-color: #d4edda;
  color: #155724;
  border-radius: 4px;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.forgot-password-input-group {
  margin-bottom: 20px;
}

.forgot-password-input-group label {
  font-size: 14px;
  margin-bottom: 8px;
  display: block;
}

.forgot-password-input-group input,
.forgot-password-input-group select {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.forgot-password-container button {
  padding: 10px 15px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
}

.forgot-password-container button:hover {
  background-color: black;
}

.forgot-password-container button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}
