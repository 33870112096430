.refund-main-div {
  display: flex;
  flex-direction: column;
}

.refund-policy {
  max-width: 1100px;
  margin: 40px auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.refund-policy h1 {
  text-align: center;
  font-size: 36px;
  color: #333;
  margin-bottom: 30px;
}

.refund-policy section {
  margin-bottom: 30px;
}

.refund-policy h2 {
  font-size: 28px;
  color: #ee1926; /* Brand color */
  margin-bottom: 15px;
}

.refund-policy p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
}

.refund-policy ul,
.refund-policy ol {
  list-style-position: inside;
  margin-left: 20px;
}

.refund-policy ul li,
.refund-policy ol li {
  font-size: 16px;
  color: #555;
  margin-bottom: 10px;
}

.refund-policy a {
  color: #ee1926; /* Brand color */
  text-decoration: none;
}

.refund-policy a:hover {
  text-decoration: underline;
}

/* Responsive Design */
@media (max-width: 768px) {
  .refund-policy {
    margin: 20px;
    padding: 15px;
  }

  .refund-policy h1 {
    font-size: 28px;
  }

  .refund-policy h2 {
    font-size: 22px;
  }

  .refund-policy p,
  .refund-policy ul li,
  .refund-policy ol li {
    font-size: 14px;
  }
}
