/* General Layout */
.precheck-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap for smaller screens */
  padding: 20px;
  gap: 20px; /* Space between sections */
}

.precheck-map-section,
.precheck-form-section {
  flex: 1 1 100%; /* Default to 100% width on small screens */
  max-width: 100%; /* Ensure they don't exceed their max width */
}

.precheck-map-section {
  display: flex;
  flex-direction: column;
}

.precheck-form-section {
  display: flex;
  flex-direction: column;
  max-width: 400px; /* Limit the width of the form */
}

.precheck-map-container {
  width: 100%;
  height: 300px;
  border-radius: 8px;
}

.precheck-delivery-info-div {
  height: auto; /* Allow form to adjust height dynamically */
  margin-top: 20px;
}

/* Paper Component */
.precheck-paper {
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.precheck-typography-heading {
  margin-bottom: 20px;
}

.precheck-textfield {
  margin-bottom: 20px;
}

.precheck-checkbox-label {
  margin-bottom: 20px;
}

.precheck-total-amount {
  margin-bottom: 20px;
}

.precheck-checkout-button {
  margin-top: 20px;
  padding: 12px;
}

/* Button Styling */
.precheck-button {
  border-radius: 8px;
}

/* Responsive Styles */
@media (min-width: 769px) {
  /* For larger screens, place the sections side by side (row) */
  .precheck-container {
    flex-direction: row;
  }

  .precheck-map-section,
  .precheck-form-section {
    flex: 1 1 48%; /* Both sections take up 48% of the available width */
  }

  .precheck-form-section {
    max-width: none; /* Allow the form to take more width */
  }
}

@media (max-width: 768px) {
  /* For smaller screens, stack the sections (column) */
  .precheck-container {
    flex-direction: column; /* Stack vertically on smaller screens */
  }

  .precheck-map-section,
  .precheck-form-section {
    flex: 1 1 100%; /* Ensure each section takes up 100% width on mobile */
  }

  .precheck-form-section {
    max-width: 100%; /* Allow the form section to take full width on smaller screens */
  }

  .precheck-map-section {
    margin-top: 30px;
  }

  .precheck-paper {
    padding: 15px; /* Reduce padding for smaller screens */
  }

  .precheck-checkout-button {
    font-size: 14px; /* Make button text smaller on mobile */
  }

  .precheck-map-container {
    height: 250px; /* Make map slightly smaller on mobile */
  }
}

@media (max-width: 480px) {
  .precheck-container {
    padding: 10px;
  }

  .precheck-map-container {
    height: 200px; /* Adjust map height for very small screens */
  }

  .precheck-textfield {
    margin-bottom: 18px; /* Reduced margin on smaller devices */
  }
}
