.product-details-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
  background-color: #fff;
}

.product-image-container {
  flex: 0.41;
  width: 295px;
  height: 441px;
}

.product-top-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.product-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: pointer;
}

.product-info-container {
  flex: 1;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.product-detail-title {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 10px;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

.tabs-container {
  width: 100%;
  margin-top: 20px;
  margin-top: 60px;
}

.product-main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.product-details-description {
  font-size: 16px;
  margin-bottom: 20px;
  line-height: 1.6;
  color: #333;
  overflow-wrap: break-word;
}

.share-id {
  margin-top: 15px;
  font-size: 14px;
  font-weight: bold;
  color: #555;
  display: flex;
  margin-right: 10px;
  gap: 5px;
}

.product-details-price {
  font-size: 20px;
  font-weight: bold;
  color: #b20808;
  margin-top: 10px;
}

.product-categories-container {
  flex: 0.35;
  margin-top: 20px;
  padding: 16px;
  border: 3px solid #e6e0e0;
  border-radius: 6px;
  background-color: #fcfcfc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: sticky;
}

.similar-products {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.similar-product-card {
  display: flex;
  width: 100%;
  height: 85px;
  border: 1px solid #ddd;
  padding: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.similar-product-card:hover {
  background-color: #e6e0e0;
  box-shadow: 0 2px 4px rgba(110, 107, 107, 0.1);
  border-radius: 6px;
}

.similar-product-card img {
  width: 50px;
  height: 50px;
}

.similar-product-card p {
  font-size: 15px;
  font-weight: 500;
}

.categories-list {
  list-style-type: none;
  padding: 0;
}

.product-category-item {
  font-size: 14px;
  margin: 2px 0;
  padding: 1px 0;
  transition: color 0.2s ease;
}

.category-link {
  text-decoration: none;
  color: #000000;
  transition: color 0.2s ease;
}

.category-link:hover {
  color: #0056b3;
}

.search-container {
  display: flex;
  flex: 1;
  margin: 0 20px;
  margin-bottom: 5px;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
}

.search-dropdown {
  padding: 8px;
  border: 1px solid #ddd;
  border-left: none;
  width: 10%;
}

.search-button {
  padding: 8px;
  background-color: #ee1925;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  color: #fff;
  font-family: sans-serif;
  font-size: smaller;
}

.search-icon {
  fill: #fff;
}

.flash-sale-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #ffd9d9;
}

.flash-sale-icon {
  font-size: 24px;
  color: #ff0000;
  margin-right: 10px;
}

.flash-sale-info {
  display: flex;
  flex-direction: column;
}

.flash-sale-title {
  font-weight: bold;
  margin: 0;
}

.flash-sale-status {
  margin: 0;
}

.buy-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  margin-top: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.buy-buttons {
  margin: 2px;
}

.buy-container {
  display: flex;
  margin-top: 20px;
}

.buy-items {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  width: 150px;
}

.quantity-controls {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.quantity-button {
  border: none;
  padding: 8px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.quantity-input {
  width: 50px;
  text-align: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 5px;
}

.icon-controls {
  display: flex;
  justify-content: space-between;
}

.icon-control {
  font-size: 24px;
  margin-right: 10px;
  cursor: pointer;
}

.buy-buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 150px;
}

.buy-button {
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #2252d1;
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
  cursor: pointer;
  margin-bottom: 10px;
}

.buy-now-button {
  background-color: #011962;
}

.icon-controls {
  display: -webkit-inline-box;
  justify-content: space-between;
  margin: auto;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: right;
  align-items: center;
  width: 50px;
  height: 40px;
  border: 2px solid #dd3333;
  border-radius: 20%;
  background-color: #dd3333;
  margin-right: 10px;
}

.icon-control {
  color: #fff;
  font-size: 14px;
  font-weight: lighter;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .product-details-container {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align items */
    padding: 10px; /* Reduce padding */
  }

  .product-image-container {
    width: 100%; /* Full width */
    height: auto; /* Maintain aspect ratio */
    margin-bottom: 20px; /* Space between image and info */
  }

  .product-info-container {
    width: 100%; /* Full width */
    padding: 10px; /* Reduce padding */
  }

  .product-categories-container {
    display: none; /* Hide categories on smaller screens */
  }

  .product-detail-title {
    font-size: 20px; /* Reduce title size */
  }

  .product-details-description,
  .product-id,
  .product-price {
    font-size: 14px; /* Reduce text size */
  }

  .buy-container {
    flex-direction: column; /* Stack buy buttons vertically */
    width: 100%; /* Full width */
    margin-top: 10px; /* Reduce margin */
  }

  .buy-items,
  .buy-buttons {
    width: 100%; /* Full width */
    margin-right: 0; /* Remove margin */
  }

  .quantity-controls {
    justify-content: center; /* Center quantity controls */
  }

  .buy-button {
    width: 100%; /* Full width buttons */
    margin-bottom: 5px; /* Reduce margin */
  }

  .icon-controls {
    justify-content: center; /* Center icon controls */
  }

  .icon-wrapper {
    margin: 0 5px; /* Reduce margin */
  }

  .search-container {
    width: 100%; /* Full width */
    margin: 0 0 10px 0; /* Adjust margins */
  }

  .search-input {
    width: calc(100% - 40px); /* Full width minus button */
  }

  .search-button {
    width: 40px; /* Fixed width for button */
  }

  /* Move tabs below product info */
  .tabs-container {
    width: 100%;
    margin-top: 20px; /* Adjust margin to fit layout */
  }
}

.read-more-link {
  color: #007bff;
  cursor: pointer;
  text-decoration: underline;
  background: none;
  border: none;
  font-size: 16px;
  padding: 0;
}

.share-social-media {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 50%;
  border: 1px solid #ccc; /* Adjust the border color as needed */
  background-color: #f9f9f9; /* Background color */
  cursor: pointer;
}

.share-icon {
  color: #555; /* Icon color */
  font-size: 18px; /* Adjust size as needed */
  vertical-align: middle;
}

.product-payment {
  text-align: center; /* Center text and inline elements */
  background-color: #faf7f8;
  padding: 7px;
  border: 2px solid #ccc;
  border-radius: 12px;
  margin-top: 10px;
}

.product-payment img {
  width: 60%;
  height: auto;
  display: block; /* Ensure the image is a block-level element */
  margin: 0 auto; /* Center the image horizontally */
}

.product-payment .text {
  margin: 10px 0; /* Add margin for spacing */
}

.categories-dropdown {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
}

/* Responsive styling for smaller screens */
@media (max-width: 728px) {
  .product-top-row {
    display: flex;
    flex-direction: column; /* Stack image and info vertically */
    align-items: center; /* Center content horizontally */
    padding: 0 10px; /* Add horizontal padding to fit the screen */
  }

  .product-image-container {
    width: 100%; /* Full width for image container */
    max-width: 350px; /* Optional max width to avoid zoomed-in effect */
  }

  .product-image-container img {
    width: 100%; /* Ensure the image itself scales with the container */
    height: auto; /* Maintain aspect ratio */
  }

  .product-info-container {
    width: 70%; /* Full width for info container */
    padding: 10px; /* Spacing for readability */
    text-align: center; /* Center text for better mobile experience */
    max-width: 350px;
  }

  .product-detail-title,
  .product-details-price,
  .product-id,
  .product-details-description {
    font-size: 1rem; /* Adjust text sizes for readability on small screens */
  }

  .buy-container {
    width: 100%; /* Full width on mobile */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    margin-top: 15px;
  }

  .tabs-container {
    width: 100%; /* Full width on mobile */
    display: flex;
    flex-direction: column; /* Stack elements vertically */
    align-items: center;
    margin-top: 15px;
  }

  .buy-buttons {
    width: 100%;
    display: flex;
    flex-direction: column; /* Stack buttons vertically */
  }

  .buy-button {
    width: 100%;
    margin: 5px 0; /* Add spacing between buttons */
  }
}


.similar-products {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.similar-product-image {
  width: 84px;
  height: 100%;
  flex-shrink: 0;
  overflow: hidden;
}

.similar-product-image img {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the space without distortion */
}

.similar-product-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px 16px;
  height: 100%;
  width: calc(100% - 84px);
}

.similar-product-name {
  font-size: 18px;
  font-weight: 400;
  color: #333;
  margin: 0;
  line-height: 1;
}

.similar-product-price {
  font-size: 12px;
  color: red; /* Make the price red */
  margin: 0;
  margin-top: 5px; /* Adds some space between the name and price */
}

.product-thumbnails {
  display: flex;
  gap: 8px; /* Add spacing between thumbnails */
  overflow-x: auto; /* Scroll horizontally if too many thumbnails */
  padding: 10px 0;
}
.thumbnail-image {
  width: 80px; /* Set the thumbnail width */
  height: 80px; /* Set the thumbnail height */
  object-fit: cover; /* Ensure the image fits the container */
  cursor: pointer; /* Indicate clickable images */
  border: 1px solid #ccc; /* Optional: Add a border for thumbnails */
  border-radius: 4px; /* Optional: Add rounded corners */
}

.image-modal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Ensure the modal content takes up full height to center the image */
}

.zoomed-image {
  width: 550px;
  height: auto; /* Ensure the aspect ratio of the image is maintained */
  object-fit: contain; /* Optional: To ensure the image fits within the container without distortion */
}

/* Modal Backdrop */
.modal-backdrop {
  background-color: rgba(0, 0, 0, 0.7); /* Blackout effect */
}

/* Image content should not have the blackout effect */
.image-modal-content {
  position: relative;
  z-index: 1; /* Make sure the image appears above the blackout */
}

.zoomed-image {
  display: block;
  max-width: 100%;
  height: auto;
}

.product-thumbnails {
  display: flex;
  gap: 10px;
  margin-top: 20px;
}

.thumbnail {
  cursor: pointer;
  border: 2px solid transparent;
  transition: border 0.2s ease-in-out;
}

.thumbnail.active-thumbnail {
  border: 2px solid #007185;
}

.thumbnail-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
}

.main-product-image {
  width: 300px;
  height: auto;
  object-fit: cover;
}