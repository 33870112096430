.container {
    display: flex; /* Align the columns horizontally */
    justify-content: space-between; /* Space the columns equally */
    align-items: center; /* Center items vertically */
    max-width: 1172px; /* Container width to fit two columns */
    margin: 0 auto; /* Center the container horizontally */
    padding: 10px; /* Add padding around the container */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.column {
    position: relative; /* Position relative to overlay image */
    flex: 1; /* Allow the columns to grow equally */
    border-radius: 12px; /* Rounded corners for each column */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add subtle shadow */
    box-sizing: border-box; /* Include padding and border in element's total width and height */
    overflow: hidden; /* Prevent content overflow */
    max-height: 290px; /* Ensure the content stays within the max height */
    height: 290px;
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Center items vertically */
    text-align: left; /* Align text to the left */
    padding: 0; /* Remove padding from the column itself */
}

.column-left {
    background-color: #DFDBFD; /* Background color for the left column */
    margin-right: 10px; /* Space between the two columns */
}

.column-right {
    background-color: #FFEAEA; /* Background color for the right column */
    margin-left: 10px; /* Space between the two columns */
}

.content {
    display: flex; /* Use flexbox for horizontal alignment */
    align-items: center; /* Center items vertically */
    height: 100%; /* Use full height available */
    width: 100%; /* Use full width available */
    padding: 10px; /* Add padding to inner content */
}

.text-content {
    flex: 1; /* Use flex to occupy half the column */
    padding: 20px; /* Padding inside text content */
    z-index: 1; /* Ensure text is on top */
}

.image-content {
    position: absolute; /* Position image to overlap half the column */
    right: 0; /* Align image to the right */
    top: 0; /* Align image to the top */
    bottom: 0; /* Align image to the bottom */
    width: 50%; /* Image takes up half the width */
    overflow: hidden; /* Prevent image overflow */
    border-top-right-radius: 12px; /* Rounded top-right corner */
    border-bottom-right-radius: 12px; /* Rounded bottom-right corner */
}

.product-image {
    width: 100%; /* Fit image within its container */
    height: 100%; /* Fill the height of the container */
    object-fit: cover; /* Cover entire area while maintaining aspect ratio */
    border-top-right-radius: 12px; /* Match column border radius */
    border-bottom-right-radius: 12px; /* Match column border radius */
}

.badge {
    background-color: #ff4081; /* Background color for badge */
    color: #fff; /* Text color for badge */
    padding: 5px 10px; /* Padding inside badge */
    border-radius: 8px; /* Rounded corners for badge */
    display: inline-block; /* Make badge inline-block */
    font-weight: bold; /* Make badge text bold */
    margin-bottom: 10px; /* Margin below the badge */
}

.banner-product-title {
    font-size: 18px; /* Font size for product title */
    font-weight: bold; /* Make product title bold */
    margin-bottom: 10px; /* Margin below the product title */
}

.details-link {
    color: #007BFF; /* Color for the link */
    text-decoration: none; /* Remove underline from link */
    font-weight: normal; /* Make link text bold */
    font-size: 14px; /* Font size for the link */
}

.details-link:hover {
    text-decoration: underline; /* Add underline on hover */
}
