/* General Styles */
.category-slider-wrapper {
  overflow: hidden;
  width: 100%;
}

.shop-container {
  display: flex;
  flex-wrap: wrap;
  padding: 20px;
}

.sidebar {
  flex: 1;
  margin-right: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 10px;
  min-width: 200px;
  width: 280px;
}

.category-header {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  margin-left: 10px;
}

.product-list {
  flex: 3;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.products-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
}

.product-item {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
  background-color: #f9f9f9;
}

.product-price {
  font-size: 14px;
  line-height: 1;
  font-weight: 500;
  color: #b20808;
  margin-bottom: 10px;
  overflow: hidden; /* Hide overflow text */
  position: absolute; /* Position the button at the bottom of the container */
  bottom: 40px; /* Space from the bottom of the container */
  left: 30%; /* Center horizontally */
}

.img-fab-container {
  position: relative;
}

.fab-wish-list {
  position: absolute;
  top: 50px;
  left: 10%;
}

.product-item-content {
  cursor: pointer;
}

.product-image {
  max-width: 100%;
  height: auto;
}

.add-to-cart-button {
  margin-top: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  border: none;
  background-color: #ee1925;
  color: #fff;
  cursor: pointer;
}

.load-more-container {
  text-align: center;
  margin-top: 20px;
}

.load-more-button {
  padding: 10px 20px;
  border-radius: 8px;
  border: none;
  background-color: #333;
  color: white;
  cursor: pointer;
}

/* Mobile Styles */
@media (max-width: 768px) {
  .shop-container {
    flex-direction: column;
    padding: 10px;
  }

  .sidebar {
    display: none; /* Hide sidebar with categories on mobile */
  }

  .product-list {
    width: 100%;
  }

  .products-grid {
    grid-template-columns: repeat(2, 1fr); /* Show two products per row */
    gap: 10px;
  }

  .add-to-cart-button {
    padding: 5px;
  }

  .search-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .search-input {
    flex-grow: 1;
    padding: 5px;
    margin-right: 5px;
  }

  .search-button {
    padding: 5px 10px;
    background-color: #011962;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
}

@media (max-width: 380px) {
  .logo {
    max-width: 80px; /* Reduce logo size */
  }

  .products-grid {
    grid-template-columns: repeat(2, 1fr);
  }

  .add-to-cart-button {
    padding: 5px;
  }

  .product-item {
    padding: 5px;
  }

  .search-input {
    width: 70%; /* Increase search input width */
  }

  .search-button {
    width: 25%;
  }
}

.skeleton-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 170px; /* Adjust based on your product item width */
  height: 300px; /* Adjust based on your product item height */
  margin: 10px;
  background-color: #f0f0f0;
  border-radius: 8px;
}

.skeleton-image {
  width: 100%;
  height: 150px;
  background-color: #ddd;
  border-radius: 8px;
  margin-bottom: 10px;
}

.skeleton-text {
  width: 80%;
  height: 20px;
  background-color: #ddd;
  border-radius: 4px;
  margin: 5px 0;
}

.skeleton-button {
  width: 50%;
  height: 30px;
  background-color: #ddd;
  border-radius: 4px;
  margin-top: 10px;
}

.skeleton-categories {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.category-item {
  cursor: pointer;
  padding: 5px;
  border-bottom: 1px solid #ddd;
  background-color: #f5f5f5;
  display: flex;
  align-items: center;
}

.category-item:hover {
  background-color: #f0f0f0;
  color: black;
}

.category-header {
  font-weight: bold;
  margin-bottom: 10px;
}

.book-list {
  font-family: Arial, sans-serif;
}

.book-list-header {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: bold;
  margin-top: 15px;
  margin-left: 10px;
  color: #777;
  line-height: 26px;
}

.book-list-items {
  list-style-type: none; /* Remove bullet points */
  padding-left: 20px; /* Indent the list items */
  font-size: 12px; /* Slightly smaller font size for list items */
}

.book-list-items li {
  margin-bottom: 5px; /* Slight space between list items */
}

.book-list-items a {
  display: block;
  text-decoration: none;
  color: #000; /* Adjust color as needed */
  margin-bottom: 5px; /* Space between links */
}

.book-list-items a:hover {
  text-decoration: underline; /* Optional hover effect */
}

.star-rating {
  display: flex;
  position: absolute; /* Position the button at the bottom of the container */
  bottom: 60px; /* Space from the bottom of the container */
  left: 27%; /* Center horizontally */
}

.full-star {
  color: #fbc02d; /* Yellow color for full stars */
  font-size: 18px; /* Adjust the size */
  margin-right: 2px; /* Adjust the spacing between stars */
}

.half-star {
  color: #fbc02d; /* Yellow color for half stars */
  font-size: 18px;
  margin-right: 2px;
}

.empty-star {
  color: #ccc; /* Grey color for empty stars */
  font-size: 18px;
  margin-right: 2px;
}

@media (max-width: 768px) {
  .products-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 12fr));
  }

  .product-item {
    width: 100%;
    margin: 0 auto;
  }
}
