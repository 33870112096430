.terms-condtions-main {
  display: flex;
  flex-direction: column;
}
.terms-conditions {
  max-width: 1000px;
  margin: 40px auto;
  padding: 20px;
}

.terms-conditions h1 {
  text-align: center;
  font-size: 32px;
  color: #333;
  margin-bottom: 20px;
}

.terms-conditions p {
  font-size: 16px;
  color: #555;
  line-height: 1.6;
  margin-bottom: 20px;
}

.terms-conditions a {
  color: #ee1926; /* Brand color for links */
  text-decoration: none;
}

.terms-conditions a:hover {
  text-decoration: underline;
}

/* Section styles */
.terms-conditions section {
  margin-bottom: 30px;
}

.terms-conditions h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 10px;
  border-bottom: 2px solid #eee;
  padding-bottom: 5px;
}

.terms-conditions section p {
  margin-bottom: 15px;
}

@media (max-width: 768px) {
  .terms-conditions {
    margin: 20px;
    padding: 15px;
  }

  .terms-conditions h1 {
    font-size: 28px;
  }

  .terms-conditions h2 {
    font-size: 20px;
  }

  .terms-conditions p {
    font-size: 14px;
  }
}
