.dashboard-container {
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
}

.dashboard-title {
  font-size: 24px;
  color: #333;
  text-align: center;
  margin-bottom: 20px;
}

.account-details {
  font-size: 18px;
  color: #555;
}

.account-details p {
  margin: 8px 0;
}

.account-details strong {
  color: #333;
}

.account-details img {
  width: 100px;
  height: 100px;
}

.loading,
.error {
  text-align: center;
  font-size: 20px;
  color: #555;
  padding: 20px;
}

.loading {
  color: #e20a0a;
}

.error {
  color: #ff4d4d;
}

.no-data {
  text-align: center;
  font-size: 18px;
  color: #777;
  padding: 20px;
}
