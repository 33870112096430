.chesi-header-top {
  display: none;
}

.has-overlay {
  overflow: inherit;
}
body:not(.page-loaded) .chesi-canvas-menu {
  display: none;
}
header.header-fixed {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 100;
}

.chesi-header-sticky {
  position: fixed;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: auto;
  min-height: 70px;
  z-index: 105;
  background: var(--chesi-light);
  display: flex;
  align-items: center;
  transform: translateY(-100%);
  -webkit-box-shadow: 0 15px 30px -4px rgb(0 0 0 / 9%);
  -moz-box-shadow: 0 15px 30px -4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 15px 30px -4px rgb(0 0 0 / 17%);
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.scroll-start .chesi-header-sticky.sticky-start {
  opacity: 1;
  transform: none;
}

body.admin-bar.scroll-start .chesi-header-sticky.sticky-start {
  top: 32px;
}

.chesi-header-part:not(.chesi-header-sticky) {
  position: relative;
}

.chesi-header-top {
  font-size: 12px;
  border-bottom: 1px solid var(--chesi-border);
  width: 100%;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.chesi-header-bottom {
  border: 1px solid var(--chesi-border);
  border-right: 0;
  border-left: 0;
}

.chesi-header-bottom.bg-trans-light {
  border-bottom: 1px solid var(--chesi-border);
}

.chesi-header-inner {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  max-width: 1580px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  min-height: 40px;
  height: 100%;
  gap: 20px;
  position: relative;
}

.chesi-header-middle .chesi-header-inner {
  min-height: 100px;
}

.chesi-header-bottom .chesi-header-inner {
  min-height: 50px;
}

.header-col {
  display: flex;
  align-items: center;
  gap: 15px;
}

.header-col.col-left {
  justify-content: flex-start;
}

.header-col.col-center {
  justify-content: center;
  flex: 1 1 auto;
}

.header-col.col-right {
  justify-content: flex-end;
}

.type-minimenu > .nav {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  list-style: none;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 500;
  line-height: 1;
}

.type-minimenu > .nav li:nth-child(1) a {
  color: var(--chesi-green);
}
.type-minimenu > .nav li:nth-child(2) a {
  color: var(--chesi-blue-soft);
}
.type-minimenu > .nav li:nth-child(3) a {
  color: var(--chesi-blue);
}
.type-minimenu > .nav li:nth-child(4) a {
  color: var(--chesi-red);
}

.type-dropdown {
  position: relative;
  height: 100%;
}

.type-dropdown > .nav {
  height: 100%;
  display: flex;
  align-items: center;
  list-style: none;
  gap: 15px;
}

.type-dropdown ul.submenu {
  list-style: none;
  font-size: 10px;
  visibility: hidden;
  opacity: 0;
  background-color: var(--chesi-light);
  padding: 15px;
  position: absolute;
  top: 100%;
  z-index: 99999;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  min-width: 100px;
  width: max-content;
  gap: 10px;
}

.col-right .type-dropdown ul.submenu {
  left: auto;
  right: 0;
}

.type-dropdown .has-dropdown {
  position: relative;
  height: 100%;
  display: flex;
  align-items: stretch;
}

.type-dropdown ul.submenu a {
  color: var(--chesi-dark);
}

.type-dropdown .dropdown-btn {
  font-size: 8px;
  transform: rotate(180deg);
}

.type-dropdown .has-dropdown > a {
  display: flex;
  align-items: center;
  gap: 5px;
}

.chesi-header-inner .top-action-btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chesi-header-top .top-action-btn svg {
  width: 18px;
  height: 18px;
}

.chesi-header-top .chesi-wc-count {
  left: 10px;
}

.header-text,
.chesi-header-top .top-action-btn {
  line-height: 1;
}

.account-page-link {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-shortcode {
  display: flex;
  align-items: center;
  gap: 13px;
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.3px;
  line-height: 0;
}

.header-shortcode a {
  font-size: 14px;
}

.bg-light .header-shortcode .ht-c-url {
  font-size: 12px;
  color: var(--chesi-text);
}

.chesi-header-middle {
  width: 100%;
  display: flex;
  align-items: center;
}

.header-mainmenu.type-menu {
  height: 100%;
}

.header-mainmenu ul.nav {
  display: flex;
  align-items: center;
  gap: 20px;
  height: 100%;
}

.header-mainmenu .nav > li > .submenu,
.header-mainmenu .nav > li > .submenu .submenu {
  list-style: none;
  font-size: 13px;
  visibility: hidden;
  opacity: 0;
  background-color: var(--chesi-light);
  padding: 20px 0;
  position: absolute;
  top: 100%;
  z-index: 99999;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 0;
  min-width: 240px;
  width: max-content;
  border-radius: 0 0 5px 5px;
}
.header-mainmenu > ul > li.menu-item--has-child:hover:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: auto;
  left: 0;
  z-index: 0;
}
@media (min-width: 1024px) {
  .mega-container {
    position: absolute;
    top: 100%;
    left: 20px;
    width: 98%;
    max-width: 1540px;
    border-top: 1px solid var(--chesi-gray);
    background-color: var(--chesi-light);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
    opacity: 0;
    visibility: hidden;
  }
}
.chesi-mobile-menu .mega-container.has-el-template {
  display: none;
}

.mega-container > li {
  display: flex;
  justify-content: center;
}

.header-mainmenu .mega-container > .submenu {
  flex-direction: row;
  max-width: 1580px;
  display: flex;
  margin: 0 auto;
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  list-style: none;
  font-size: 12px;
  gap: 20px;
}

.header-mainmenu .mega-container .submenu .submenu {
  list-style: none;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header-mainmenu .mega-container .column-title {
  font-size: 14px;
  border-bottom: 1px solid var(--chesi-border);
  margin-bottom: 10px;
  padding-bottom: 10px;
}

.header-mainmenu .nav > li > .submenu .submenu {
  left: 100%;
  top: calc(-100% - -15px);
}

.col-right .header-mainmenu .nav > li > .submenu {
  left: auto;
  right: 0;
}

.col-right .header-mainmenu .nav > li > .submenu .submenu {
  left: auto;
  right: 100%;
}

.col-right .header-mainmenu .submenu li.has-dropdown .dropdown-btn {
  transform: rotate(-90deg);
}
.menu-item.has-icon > a {
  display: flex;
  align-items: center;
  gap: 10px;
  width: -webkit-fill-available;
}
.header-mainmenu > .nav > li:not(.mega-parent) {
  display: flex;
  position: relative;
  align-items: center;
  height: 100%;
}

.header-mainmenu > .nav > li.mega-parent {
  display: flex;
  align-items: center;
  height: 100%;
}

.header-mainmenu > .nav > li > a {
  font-size: 13px;
  font-weight: 500;
  color: var(--chesi-gray-dark);
  text-transform: uppercase;
  padding: 0;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  gap: 6px;
}
.menu-item > a > svg {
  width: 14px;
  height: 14px;
  fill: currentColor;
}
.header-mainmenu > .nav > li > .submenu > li,
.header-mainmenu > .nav > li > .submenu .submenu > li {
  width: 100%;
  position: relative;
  /* padding: 0 15px; */
}

.header-mainmenu .submenu > li > a {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 10px 20px;
}

.header-mainmenu .menu-item .menu-label {
  position: absolute;
  top: -26px;
  max-height: 16px;
  display: flex;
  right: -1px;
  font-size: 8px;
  padding: 4px 5px;
  color: var(--chesi-light);
  background-color: var(--chesi-blue-soft);
  border-radius: 3px;
  letter-spacing: 0.5px;
  align-items: center;
  justify-content: center;
  align-content: center;
  text-transform: uppercase;
  font-weight: 500;
}
.header-mainmenu .menu-item .submenu .menu-label {
  top: 8px;
  right: 15px;
  left: auto;
}
.menu-item-mega-parent {
  position: static;
}

.mega-parent.column-5 > .mega-container > ul.submenu > li {
  width: 20%;
  flex: 0 0 20%;
}

.header-mainmenu .dropdown-btn {
  display: block;
  transform: rotate(180deg);
  font-size: 7px;
  font-weight: bold;
  color: var(--chesi-text);
}

.header-mainmenu .submenu li.has-dropdown .dropdown-btn {
  transform: rotate(90deg);
  position: absolute;
  right: 15px;
}

.chesi-category-menu {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
}

.chesi-category-menu .menu-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--chesi-light);
  background: var(--chesi-primary);
  padding: 10px 15px;
  text-transform: uppercase;
  line-height: 1;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  gap: 10px;
}

.chesi-category-menu .title {
  display: flex;
  align-items: center;
  gap: 10px;
}

.chesi-header-bottom .menu-title {
  min-width: 230px;
}

.chesi-category-menu .menuBars {
  width: 18px;
  height: 18px;
}

.chesi-category-menu .icon-arrow {
  display: block;
  transform: rotate(180deg);
  font-size: 10px;
}

.chesi-category-menu .submenu {
  min-width: 230px;
  position: absolute;
  padding: 0;
  background-color: var(--chesi-light);
  border-radius: 0 0 5px 5px;
  border: none;
  opacity: 0;
  visibility: hidden;
  left: 0;
  top: 100%;
  z-index: 99;
  display: flex;
  flex-direction: column;
  gap: 0;
  justify-content: space-between;
  align-content: flex-start;
}

.chesi-category-menu.always-open > .submenu {
  opacity: 1;
  visibility: visible;
  transform: none;
  pointer-events: unset;
}

.chesi-category-menu .submenu .submenu {
  right: auto;
  left: 100%;
  top: 0px;
}

.col-right .chesi-category-menu .submenu {
  left: auto;
  right: 0;
}

.col-right .chesi-category-menu .submenu .submenu {
  right: 100%;
  left: auto;
}

.chesi-category-menu .primary-cats > li,
.chesi-category-menu .submenu > li {
  margin-left: 0;
  text-align: left;
  display: flex;
  position: relative;
  padding: 8px 15px;
  align-items: center;
  flex-direction: row;
}

.chesi-category-menu .primary-cats > li:not(last-child),
.chesi-category-menu .submenu > li:not(last-child) {
  border-bottom: 1px solid var(--chesi-border);
}

.chesi-category-menu .product_cat {
  width: 100%;
  display: flex;
  text-transform: capitalize;
  line-height: 1;
  align-items: center;
  gap: 10px;
}
header
  .chesi-vertical-catmenu-primary.submenu
  .menu-item-has-children
  > .product_cat:after {
  font-family: "ninetheme-font";
  content: "\f115";
  position: absolute;
  right: 18px;
  transform: rotate(90deg);
  font-size: 7px;
}
.bg-light .chesi-category-menu > .submenu li:hover > .product_cat:after {
  color: var(--chesi-light);
}
.chesi-category-menu .submenu .dropdown-btn {
  display: block;
  position: relative;
  font-size: 10px;
  color: currentColor;
}

.col-right .chesi-category-menu .submenu .dropdown-btn {
  transform: rotate(180deg);
}

.type-dropdown ul.submenu,
.header-mainmenu .nav > li > .submenu,
.header-mainmenu .nav > li > .submenu .submenu,
.header-mainmenu .mega-container,
.chesi-category-menu .submenu {
  -webkit-box-shadow: 0 15px 30px -4px rgb(0 0 0 / 9%);
  -moz-box-shadow: 0 15px 30px -4px rgba(0, 0, 0, 0.09);
  box-shadow: -4px -2px 30px 2px rgb(0 0 0 / 17%);
  transform: translateY(15px) translateZ(0);
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    visibility 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  pointer-events: none;
}

.header-mainmenu .nav > li:hover > .submenu,
.header-mainmenu .nav > li.hover > .submenu,
.header-mainmenu .nav .submenu li:hover > .submenu,
.header-mainmenu .nav > li:hover > .mega-container,
.header-mainmenu .nav > li.hover > .mega-container,
.type-dropdown ul > li.open > .submenu,
.chesi-category-menu:hover > .submenu,
.chesi-category-menu.active > .submenu,
.chesi-category-menu .submenu li:hover > .submenu,
.chesi-category-menu.fixed-submenu.active li:hover > .submenu {
  opacity: 1;
  visibility: visible;
  transform: none;
  pointer-events: unset;
}

.bg-light:not(.bg-trans-light) {
  background-color: var(--chesi-light);
}

.bg-dark:not(.bg-trans-dark) {
  background-color: var(--chesi-dark);
  color: var(--chesi-light);
}

.bg-dark .top-action-btn svg,
.bg-dark .chesi-svg-icon,
.bg-trans-light .chesi-svg-icon {
  fill: var(--chesi-light);
}

.bg-light .chesi-svg-icon {
  fill: var(--chesi-text);
}

.bg-dark .menuBars,
.bg-trans-light .menuBars {
  stroke: var(--chesi-light);
}

.bg-light .menuBars {
  stroke: var(--chesi-light);
}

.top-action-btn .shopCompare.chesi-svg-icon {
  max-width: 28px !important;
  max-height: 28px !important;
}

.bg-dark .header-text,
.bg-dark .header-text a,
.bg-dark .account-page-link,
.bg-dark .nav > li > a,
.bg-dark .header-mainmenu > ul > li > a,
.bg-dark .header-mainmenu .submenu > li > a,
.bg-dark .chesi-ajax-product-title,
.bg-dark .chesi-btn-text,
.bg-dark .chesi-ajax-product-price,
.bg-dark .header-shortcode a,
.bg-dark .chesi-ajax-selected,
.bg-dark .chesi-category-menu .submenu a,
.bg-dark .chesi-category-menu li,
.bg-dark .type-dropdown ul.submenu a,
.bg-trans-light .header-text,
.bg-trans-light .header-text a,
.bg-trans-light .account-page-link,
.bg-trans-light .nav > li > a,
.bg-trans-light .header-mainmenu > ul > li > a,
.bg-trans-light .header-shortcode a,
.bg-trans-light .chesi-ajax-selected,
.bg-trans-light .header-mainmenu .submenu > li > a,
.bg-trans-light .chesi-ajax-product-title,
.bg-trans-light .chesi-btn-text,
.bg-trans-light .chesi-ajax-product-price,
.bg-trans-light .chesi-category-menu .submenu a,
.bg-trans-light .chesi-category-menu li,
.bg-trans-light .type-dropdown ul.submenu a {
  color: var(--chesi-light);
}

.bg-light .header-text,
.bg-light .header-text a,
.bg-light .account-page-link,
.bg-light .nav > li > a,
.bg-light .header-mainmenu > ul > li > a,
.bg-light .header-mainmenu .submenu > li > a,
.bg-light .chesi-ajax-product-title,
.bg-light .chesi-btn-text,
.bg-light .chesi-ajax-product-price,
.bg-light .header-shortcode a,
.bg-light .chesi-category-menu .submenu > li > a,
.bg-light .chesi-category-menu li > a,
.bg-light .type-dropdown ul.submenu a,
.bg-trans-dark .header-text,
.bg-trans-dark .header-text a,
.bg-trans-dark .account-page-link,
.bg-trans-dark .nav > li > a,
.bg-trans-dark .header-mainmenu > ul > li > a,
.bg-trans-dark .header-shortcode a,
.bg-trans-dark .chesi-ajax-selected,
.bg-trans-dark .header-mainmenu .submenu > li > a,
.bg-trans-dark .chesi-ajax-product-title,
.bg-trans-dark .chesi-btn-text,
.bg-trans-dark .chesi-ajax-product-price,
.bg-trans-dark .chesi-category-menu .submenu a,
.bg-trans-dark .chesi-category-menu li,
.bg-trans-dark .type-dropdown ul.submenu a {
  color: var(--chesi-dark);
}

.bg-trans-dark,
.bg-trans-light {
  background-color: transparent;
}

.bg-dark .type-dropdown ul.submenu,
.bg-dark .header-mainmenu ul.submenu,
.bg-dark .category-select-wrapper .category-list,
.bg-dark .chesi-category-menu .submenu {
  background-color: var(--chesi-dark);
}

.chesi-header .panel-open .menuBars {
  stroke: var(--chesi-dark);
}

.chesi-header .panel-open {
  display: flex;
  align-items: center;
  align-content: center;
}

.bg-light .chesi-category-menu .submenu li > a > img {
  max-width: 28px;
  border: 1px solid;
  border-color: var(--chesi-border);
  padding: 4px;
  border-radius: 5px;
  background: #eee;
}

.bg-light .chesi-category-menu .category-title {
  font-weight: 500;
}

.bg-light .chesi-category-menu > ul.submenu > li:hover > a img {
  background: var(--chesi-blue-bg);
  border-color: var(--chesi-blue-bg);
}

.bg-light .chesi-category-menu .submenu > li:hover {
  background: var(--chesi-primary);
}
.bg-light .chesi-category-menu li.current-cat {
  background: var(--chesi-secondary);
}

.bg-light .chesi-category-menu .submenu > li:hover > a,
.bg-light .chesi-category-menu .submenu > li.current-cat > a,
.bg-light .chesi-category-menu .submenu > li.current-cat,
.bg-light .chesi-category-menu .submenu > li:hover {
  color: var(--chesi-light);
}

.chesi-header-middle .nt-header-callus-container {
  margin: 0 10px;
}
.chesi-header-middle .nt-header-callus-icon i {
  font-size: 30px;
  color: var(--chesi-green);
}

.chesi-header-middle .nt-header-callus {
  display: flex;
  gap: 15px;
  align-items: center;
}

.chesi-header-middle .nt-header-callus-text {
  display: flex;
  flex-direction: column-reverse;
  gap: 3px;
}

.chesi-header-middle .nt-header-phone-text {
  color: var(--chesi-text);
  text-transform: uppercase;
  font-size: 8px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.chesi-header-middle .nt-header-phone-number {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #222529;
}

.nt-header-type-2 .header-text {
  min-width: 210px;
}

.nt-header-type-2 .chesi-header-middle .nt-header-callus-container {
  border: 1px solid;
  border-color: var(--chesi-red);
  border-radius: 50px;
  padding: 5px;
}
.nt-header-type-2 .chesi-header-middle .nt-header-callus-icon i {
  font-size: 20px;
}
.nt-header-type-2 .chesi-header-middle .nt-header-callus-icon {
  background: var(--chesi-red);
  color: #fff;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
}

.chesi-header-top .nt-header-callus {
  display: flex;
  gap: 10px;
  align-items: center;
}

.chesi-header-top .nt-header-callus-container {
  font-size: 12px;
  background: var(--chesi-blue);
  padding: 7px 10px;
  color: var(--chesi-light);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  gap: 5px;
}

.chesi-header-top .nt-header-phone-number {
  font-size: 13px;
  color: white;
}

.chesi-header-top .nt-header-callus-text {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  gap: 10px;
}

.chesi-header-top .nt-header-callus-container * {
  color: var(--chesi-light);
}

/* popup search */

.chesi-popup-search {
  position: fixed;
  top: -100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  height: 100%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  background-color: var(--chesi-light);
  z-index: 106;
  padding: 100px;
}
.chesi-popup-search.style-2 {
  height: auto;
  box-shadow: 0 0 20px #ddd;
}

.chesi-popup-search.style-3 {
  top: 0;
  right: auto;
  left: -100%;
  height: 100%;
  width: 420px;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 50px 30px;
  flex-direction: column;
  box-shadow: none;
  border-left: 1px solid var(--chesi-border);
}
.chesi-popup-search.style-3 .search-col .chesi-ajax-search-results ul {
  flex-direction: column;
}
.chesi-popup-search.style-3
  .search-col
  .chesi-ajax-search-results
  ul
  li:nth-child(2n + 1) {
  border-right: 0;
}
.chesi-popup-search.style-3 .chesi-ajax-search-results {
  max-height: calc(100vh - 150px);
  min-width: auto;
}
.chesi-popup-search.style-3 .search-col .chesi-category-menu {
  display: flex;
  flex-direction: column;
  align-content: stretch;
  flex-wrap: nowrap;
  justify-content: center;
}

.chesi-popup-search.style-3
  .search-col
  .chesi-vertical-catmenu-primary.submenu {
  flex-direction: column;
  gap: 5px;
  display: flex;
  align-content: stretch;
  width: 100%;
  flex-wrap: nowrap;
  overflow: hidden;
  overflow-y: auto;
  padding-bottom: 20px;
}

.chesi-popup-search.style-3 .chesi-popup-search .panel-close {
  top: 15px;
  left: -15px;
  right: auto;
}

.chesi-popup-search.active {
  top: 0;
  visibility: visible;
  z-index: 99999;
  opacity: 1;
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    visibility 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    top 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.chesi-popup-search.style-3.active {
  left: 0;
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    visibility 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    left 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.chesi-popup-search.active .search-form-found {
  position: relative;
  z-index: 9;
}
.search-container {
  max-width: 1200px;
}
.search-col {
  flex: 0 0 100%;
  width: 100%;
}
.search-col .chesi-ajax-product-search {
  max-width: 100%;
  z-index: 1;
}
.search-col .chesi-ajax-product-search form {
  border: 0;
  border-bottom: 2px solid var(--chesi-border);
  border-radius: 0;
}
.search-col .chesi-ajax-search-results {
  max-width: 100%;
}
.search-col .chesi-ajax-search-results ul {
  flex-direction: row;
  flex-wrap: wrap;
}
.search-col .chesi-ajax-search-results ul li {
  padding: 10px 20px;
  flex: 0 0 50%;
}
.search-col .chesi-ajax-search-results ul li:nth-child(2n + 1) {
  border-right: 1px dashed var(--chesi-border);
}
.search-col .chesi-scrollbar {
  padding-right: 0;
  padding-left: 0;
}
.search-col .chesi-ajax-product-search .category-select-wrapper {
  border: 0;
}
.search-col .chesi-ajax-product-search button.chesi-ajax-search-submit,
.search-col .chesi-ajax-product-search button.chesi-ajax-search-submit:hover,
.search-col .chesi-ajax-close-search-results {
  color: var(--chesi-dark);
  background-color: transparent;
}
.search-col .chesi-ajax-product-search button.chesi-ajax-search-submit svg,
.search-col
  .chesi-ajax-product-search
  button.chesi-ajax-search-submit:hover
  svg {
  fill: var(--chesi-dark);
}
.search-col .cat-heading {
  font-size: 18px;
  margin-top: 40px;
  position: relative;
  display: block;
  padding-bottom: 0;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 500;
}
.search-col .chesi-category-menu {
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 1;
  flex-wrap: wrap;
  max-height: calc(100vh - 250px);
  overflow: hidden;
  overflow-y: auto;
}
.search-col .chesi-vertical-catmenu-primary.submenu {
  position: relative;
  opacity: 1;
  visibility: visible;
  flex-direction: row;
  background: transparent;
  box-shadow: none;
  border: 0;
  transform: none;
  gap: 1px;
  flex-wrap: wrap;
}
.chesi-popup-search.style-2
  .search-col
  .chesi-vertical-catmenu-primary.submenu {
  padding-top: 2px;
  padding-bottom: 2px;
  max-height: 200px;
  overflow: hidden;
  overflow-y: auto;
}
.search-col .chesi-category-menu .submenu > li {
  border: 1px solid var(--chesi-border);
  flex: 1 0 20%;
  min-height: 48px;
}
.search-col .chesi-category-menu .submenu .submenu {
  right: auto;
  left: 0;
  top: 100%;
  width: 100%;
  display: none;
}
.chesi-popup-search .panel-close {
  position: absolute;
  top: 10px;
  right: 20px;
}
body.admin-bar .chesi-popup-search .panel-close {
  top: 15px;
  right: 20px;
}
body.admin-bar .chesi-popup-search.style-3 .panel-close {
  top: 10px;
  right: 10px;
}

.chesi-popup-search .cat-item:hover {
  background-color: var(--chesi-primary);
  border-color: var(--chesi-primary);
  -webkit-box-shadow: 0 15px 30px -4px rgb(0 0 0 / 9%);
  -moz-box-shadow: 0 15px 30px -4px rgba(0, 0, 0, 0.09);
  box-shadow: -4px -2px 30px 2px rgb(0 0 0 / 17%);
}
.chesi-popup-search .cat-item:hover,
.chesi-popup-search .cat-item:hover > a {
  color: var(--chesi-light);
  opacity: 1;
}
.chesi-popup-search .nt-icon-up-chevron.dropdown-right {
  transform: rotate(90deg);
  font-size: 10px;
}

/* promotion top bar */
.chesi-promotion-bar .inner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;
  min-height: 30px;
  max-width: 1420px;
  padding-left: 20px;
  padding-right: 20px;
  margin-left: auto;
  margin-right: auto;
  font-size: 12px;
  color: #fff;
  transition: height 0.5s, padding 0.5s;
  background: none;
  position: relative;
}
#promotionBar.closed {
  height: 0;
  padding: 0;
  overflow: hidden;
}
.chesi-promotion-bar .panel-close {
  position: absolute;
  right: 15px;
  top: 6px;
  width: 20px;
  height: 20px;
  border-width: 5px;
  background-color: transparent;
}
.chesi-promotion-bar.grad-anim-1 {
  background: linear-gradient(270deg, #000fe8, #e80027);
}
.grad-anim-1 {
  background-size: 400% 400%;
  -webkit-animation: grad-anim-1 22s ease infinite;
  -moz-animation: grad-anim-1 22s ease infinite;
  animation: grad-anim-1 22s ease infinite;
}

@-webkit-keyframes grad-anim-1 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes grad-anim-1 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes grad-anim-1 {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Header style-2 */

.chesi-header.bg-light.style-2 .chesi-header-bottom {
  background: var(--chesi-blue-dark);
}

.chesi-header.bg-light.style-2
  .chesi-header-bottom
  .header-mainmenu
  .nav
  > li
  > a,
.chesi-header.bg-light.style-2 .chesi-header-bottom .account-page-link,
.chesi-header.bg-light.style-2 .chesi-header-bottom svg,
.chesi-header.bg-light.style-2 .header-text a,
.chesi-header.bg-light.style-2 .chesi-header-bottom .chesi-ajax-selected {
  color: var(--chesi-light);
  fill: var(--chesi-light);
}
.chesi-header.bg-light.style-2 .chesi-header-bottom .panel-open svg {
  stroke: var(--chesi-light);
}
.chesi-header.bg-light.style-2
  .chesi-header-bottom
  .chesi-ajax-product-search
  .category-select-wrapper,
.chesi-header.bg-light.style-2
  .chesi-header-bottom
  .chesi-ajax-product-search
  form {
  border-width: 2px solid;
  border-color: var(--chesi-primary);
}
.chesi-header.bg-light.style-2
  .chesi-header-bottom
  .chesi-ajax-product-search
  form {
  height: 38px;
}
.chesi-header.bg-light.style-2
  .chesi-header-bottom
  .type-minimenu
  > .nav
  li:nth-child(3)
  a {
  color: var(--chesi-yellow);
}
.chesi-header.bg-light.style-2
  .chesi-header-bottom
  .header-minimenu.type-dropdown
  > ul
  > li
  > a {
  color: var(--chesi-light);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.chesi-header.style-2 .chesi-header-bottom .nt-header-callus {
  text-transform: uppercase;
  font-size: 12px;
}
.chesi-header.style-2 .chesi-header-bottom .nt-header-callus-icon i {
  font-size: 10px;
  margin-right: 5px;
}
.chesi-header.bg-light.style-2 .chesi-header-middle .header-text {
  background-color: var(--chesi-green);
  padding: 8px;
  border-radius: 5px;
}
.chesi-header.bg-light.style-2 .chesi-header-middle .header-text:hover {
  background-color: var(--chesi-primary);
}
.chesi-header.bg-light.style-2 .chesi-header-middle .header-text i {
  color: var(--chesi-light);
}

.chesi-header.bg-light.style-2 .chesi-header-middle .nt-header-phone-text {
  color: var(--chesi-green-bg);
}
.chesi-header.bg-light.style-2 .chesi-header-middle .nt-header-phone-number {
  color: var(--chesi-light);
}

/* Header style-2 end */

/* Header style-3 */

.chesi-header.style-3 .chesi-header-top {
  background: var(--chesi-primary);
}

.chesi-header.style-3 .chesi-header-top .nt-header-callus-container {
  background: var(--chesi-blue);
  color: var(--chesi-light);
}

.chesi-header.style-3 .chesi-header-top .header-mainmenu .nav > li > a,
.chesi-header.style-3 .chesi-header-top .account-page-link,
.chesi-header.style-3 .chesi-header-top svg,
.chesi-header.style-3 .header-text a,
.chesi-header.style-3 .chesi-header-top .header-shortcode a {
  color: var(--chesi-light);
  fill: var(--chesi-light);
}
.chesi-header.style-3 .chesi-header-top .panel-open svg {
  stroke: var(--chesi-light);
}

.chesi-header.style-3
  .chesi-header-top
  .type-minimenu
  > .nav
  li:nth-child(3)
  a {
  color: var(--chesi-yellow);
}
.chesi-header.style-3
  .chesi-header-top
  .header-minimenu.type-dropdown
  > ul
  > li
  > a {
  color: var(--chesi-light);
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: 0.3px;
}
.chesi-header.style-3 .chesi-header-top .nt-header-callus {
  text-transform: uppercase;
  font-size: 10px;
}
.chesi-header.style-3 .chesi-header-top .nt-header-callus-icon i {
  font-size: 15px;
  margin-right: 0px;
}
.chesi-header.style-3 .chesi-header-middle .header-text {
  background-color: var(--chesi-green);
  padding: 8px;
  border-radius: 5px;
}
.chesi-header.style-3 .chesi-header-middle .header-text:hover {
  background-color: var(--chesi-primary);
}
.chesi-header.style-3 .chesi-header-middle .header-text i {
  color: var(--chesi-light);
}

.chesi-header.style-3 .chesi-header-middle .nt-header-phone-text {
  color: var(--chesi-green-bg);
}
.chesi-header.style-3 .chesi-header-middle .nt-header-phone-number {
  color: var(--chesi-light);
}

/* Header style-3 end */

/* Header style-4 */

.chesi-header.style-4 .chesi-header-top {
  background: var(--chesi-gray-soft);
}
.chesi-header.style-4 .chesi-header-top .nt-header-callus-container {
  background: var(--chesi-primary);
}
.chesi-header.style-4 .chesi-category-menu .menu-title,
.chesi-header.style-4
  .chesi-ajax-product-search
  button.chesi-ajax-search-submit,
.chesi-header.style-4
  .chesi-ajax-product-search
  button.chesi-ajax-search-submit:hover,
.chesi-header.style-4 .chesi-ajax-close-search-results,
.chesi-header.style-4 .chesi-wc-count {
  background-color: var(--chesi-red);
  border-radius: 3px;
}

.chesi-header.style-4 .chesi-header-bottom {
  border: 0;
  border-top: 1px solid;
  border-color: var(--chesi-border);
  border-right: 0;
  border-left: 0;
  padding-top: 15px;
}
.chesi-header.style-4 .chesi-header-middle .nt-header-callus-icon i {
  font-size: 24px;
  color: var(--chesi-light);
}
.chesi-header.style-4 .chesi-header-middle .nt-header-callus {
  gap: 10px;
}
.chesi-header.style-4 .chesi-header-middle .nt-header-callus-icon {
  background-color: var(--chesi-green);
  color: var(--chesi-light);
  padding: 5px 7px;
  border-radius: 3px;
}

/* Header style-4 end */

/* Header style-5 start */

.style-5 .header-mainmenu > ul > li > a > .dropdown-btn {
  color: var(--chesi-light);
}

.style-5 .chesi-header-bottom {
  border: 0;
  background-color: var(--chesi-red-dark);
}

.style-5 .chesi-header-top {
  background-color: var(--chesi-gray-soft);
}

.style-5 .chesi-header-middle .nt-header-callus {
  border: 2px solid #009367;
  padding: 10px;
  border-radius: 5px;
}

.style-5 .chesi-ajax-product-search button.chesi-ajax-search-submit,
.style-5 .chesi-ajax-product-search button.chesi-ajax-search-submit:hover,
.style-5 .chesi-ajax-close-search-results {
  background-color: var(--chesi-red);
}

.style-5 .chesi-wc-count {
  background: var(--chesi-red-soft);
  color: var(--chesi-red-dark);
}

.style-5 .chesi-category-menu .menu-title,
.style-5 .chesi-category-menu .submenu > li:hover {
  background: var(--chesi-red);
}

.style-5 .chesi-header-bottom .header-mainmenu .nav > li > a,
.style-5 .chesi-header-bottom .account-page-link,
.style-5 .chesi-header-bottom svg,
.style-5 .header-text a,
.style-5 .type-minimenu > .nav li a,
.style-5 .chesi-header-bottom .header-shortcode a {
  color: var(--chesi-light);
  fill: var(--chesi-light);
}

/* Header style-5 end */

/* header style 7 */
.chesi-header.bg-light.style-7 .chesi-header-bottom .menu-title {
  min-width: 230px;
  border-radius: 10px 0 0 10px;
}

.chesi-header.bg-light.style-7 .chesi-header-top {
  border: 0;
}

.chesi-header.bg-light.style-7 .chesi-header-middle {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.chesi-header.bg-light.style-7 .chesi-header-bottom {
  border: 0;
  margin-bottom: 20px;
}

.chesi-header.bg-light.style-7 .chesi-header-bottom .chesi-header-inner {
  min-height: 60px;
  background-color: #f5f6f8;
  max-width: 1380px !important;
  border-radius: 10px;
  padding-left: 0;
}

.chesi-header.bg-light.style-7 .chesi-ajax-product-search form {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  border: 0;
  height: 50px;
  border-radius: 10px;
  background-color: #f5f6f8;
  padding: 0 5px;
}
.chesi-header.bg-light.style-7
  .chesi-ajax-product-search
  button.chesi-ajax-search-submit,
.chesi-header.bg-light.style-7
  .chesi-ajax-product-search
  button.chesi-ajax-search-submit:hover,
.chesi-header.bg-light.style-7 .chesi-ajax-close-search-results,
.chesi-header.bg-light.style-7 .chesi-ajax-product-search .error {
  background-color: transparent;
}
.chesi-header.bg-light.style-7
  .chesi-ajax-product-search
  .category-select-wrapper {
  border: 1px solid #d6dae0;
  background-color: #fff;
  max-height: 40px;
  border-radius: 8px;
  margin-right: 5px;
}

.chesi-header.bg-light.style-7
  .chesi-ajax-product-search
  button.chesi-ajax-search-submit
  svg {
  fill: var(--chesi-text-soft);
}

@media (max-width: 1200px) {
  .chesi-category-menu.always-open > .submenu {
    opacity: 0;
    visibility: hidden;
  }
  .chesi-category-menu.always-open.show > .submenu,
  .search-col .chesi-category-menu.always-open > .submenu {
    opacity: 1;
    visibility: visible;
  }
}

@media (min-width: 1024px) {
  body.page-loaded.has-minibar,
  body.page-loaded.has-minibar .chesi-header-sticky {
    margin-left: 50px;
    transition: margin-left 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  }
  .type-dropdown ul > li:hover > .submenu {
    opacity: 1;
    visibility: visible;
    transform: none;
    pointer-events: unset;
  }
}

@media (max-width: 1024px) {
  .chesi-header .hidden-mobile {
    display: none;
  }

  .search-col .chesi-category-menu .submenu > li {
    flex: 1 0 calc((100% / 3) - 1px);
  }

  .chesi-header-top .chesi-header-inner {
    gap: 0;
  }

  .chesi-header-top .header-col.col-center {
    justify-content: flex-start;
  }
  .chesi-header-inner {
    padding-left: 15px;
    padding-right: 15px;
  }
}

/* wp admin-bar mobile */

@media (max-width: 782px) {
  .admin-bar .chesi-side-panel {
    top: 46px;
  }

  .admin-bar .chesi-promotion-bar .panel-close {
    top: 5px;
  }
}

@media (max-width: 768px) {
  .chesi-popup-search {
    padding: 60px 30px;
  }
  .search-col .chesi-category-menu .submenu > li {
    flex: 1 0 calc((100% / 2) - 1px);
  }
}
@media (max-width: 480px) {
  .chesi-popup-search {
    padding: 25px 20px;
  }
  .search-col .chesi-ajax-product-search {
    margin-top: 30px;
  }
  .search-col .chesi-vertical-catmenu-primary {
    max-height: calc(100vh - 200px);
    overflow: auto;
    padding-bottom: 10px;
  }
  .search-col .chesi-category-menu .submenu > li {
    flex: 0 0 100%;
  }
  .search-col .chesi-ajax-search-results {
    min-width: auto;
  }
  .search-col .chesi-ajax-search-results ul li {
    flex: 0 0 100%;
  }
  .search-col .chesi-ajax-search-results ul li:nth-child(2n + 1) {
    border-right: 0;
  }
  .search-col .chesi-scrollbar {
    padding-right: 0;
    padding-left: 0px;
    gap: 5px;
  }
  .header-shortcode {
    gap: 7px;
  }
  body.admin-bar.scroll-start .chesi-header-sticky.sticky-start {
    top: -6px;
  }
  body.admin-bar .chesi-popup-search .panel-close {
    top: 15px;
    right: 25px;
  }
}

/* mobile header */
.chesi-header-mobile {
  min-height: 80px;
  display: none;
  align-items: center;
  width: 100%;
  height: auto;
  z-index: 99;
  -webkit-box-shadow: 0 15px 30px -4px rgb(0 0 0 / 9%);
  -moz-box-shadow: 0 15px 30px -4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 0px 30px -4px rgb(0 0 0 / 17%);
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    top 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.chesi-header-mobile > div {
  flex: 0 0 100%;
}
.chesi-header-mobile-fixed {
  min-height: 80px;
  display: none;
}
.chesi-header-mobile .search-form-found {
  padding: 0 15px;
  min-height: 46px;
}
.chesi-header-mobile.sticky-ready .search-form-found {
  display: none;
}
.chesi-header-mobile .chesi-ajax-search-results {
  max-width: 100%;
}
.chesi-mobile-menu {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  max-width: 450px;
  z-index: 9999999;
  background-color: var(--chesi-light);
  visibility: hidden;
  -webkit-box-shadow: 0 15px 30px -4px rgb(0 0 0 / 9%);
  -moz-box-shadow: 0 15px 30px -4px rgba(0, 0, 0, 0.09);
  box-shadow: 0 15px 30px -4px rgb(0 0 0 / 17%);
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    visibility 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    left 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.chesi-mobile-menu.active {
  transform: none;
  left: 0;
  visibility: visible;
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    visibility 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    left 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.chesi-mobile-menu .panel-close {
  border-radius: 0;
  width: 40px;
  height: 40px;
  right: -40px;
  top: 0;
  z-index: 5;
}
.mobile-menu-inner:not(.has-el-template) {
  display: flex;
  height: calc(100% - 55px);
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
  flex: 0 0 100%;
  flex-wrap: wrap;
  position: relative;
}
.mobile-menu-inner.has-el-template {
  display: block;
  height: calc(100% - 55px);
  overflow: hidden;
  overflow-y: auto;
  width: 100%;
}
.mobile-menu-inner.chesi-scrollbar {
  padding-right: 0;
  height: calc(100vh - 55px);
}
.mobile-menu-tabs {
  display: flex;
  align-items: center;
  gap: 1px;
  width: 100%;
}
.mobile-menu-tab {
  flex: 1 0 auto;
  padding: 16px 10px;
  text-align: center;
  background-color: var(--chesi-light);
  color: var(--chesi-dark);
  text-transform: uppercase;
  cursor: pointer;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 2px solid;
  border-color: var(--chesi-border);
  font-weight: 500;
  letter-spacing: 0.1px;
}
.mobile-menu-tab:hover,
.mobile-menu-tab.active {
  background-color: var(--chesi-gray-soft);
  border-color: var(--chesi-gray);
}
.mobile-menu .menu-item .menu-label {
  display: none;
}

.mobile-menu .menu-divider {
  border-top: 1px dashed var(--chesi-border);
  margin: 30px 0;
}
.mobile-menu .nav.cloned {
  border-top: 1px solid var(--chesi-border);
}
.mobile-menu .submenu,
.mobile-category-menu .submenu {
  display: none;
  flex-direction: column;
}

.has-tab .mobile-menu,
.has-tab .mobile-category-menu {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transform: translateX(-100%);
  opacity: 0;
  transition: opacity 0.4s cubic-bezier(0.19, 1, 0.22, 1),
    transform 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}
.no-tab .mobile-menu,
.no-tab .mobile-category-menu {
  width: 100%;
}
.mobile-menu.menu-active,
.mobile-category-menu.menu-active {
  transform: translateX(0);
  opacity: 1;
}
.mobile-menu li,
.mobile-category-menu li {
  list-style: none;
}
.mobile-menu li > a,
.mobile-category-menu li > a {
  padding: 0 20px;
  border-bottom: 1px solid var(--chesi-border);
  position: relative;
  display: flex;
  align-items: center;
  gap: 15px;
  width: -webkit-fill-available;
  min-height: 52px;
  font-weight: 500;
  font-size: 13px;
}
.mobile-menu li.open > a,
.mobile-menu li > a:hover,
.mobile-category-menu li.open > a,
.mobile-category-menu li > a:hover {
  background-color: var(--chesi-gray-soft);
  color: var(--chesi-dark);
  opacity: 1;
}
.mobile-menu li.open > a,
.mobile-menu li > a:hover,
.mobile-category-menu li.open > a,
.mobile-category-menu li > a:hover .mobile-menu-tab:hover,
.mobile-menu-tab.active {
  -webkit-tap-highlight-color: transparent;
}

.mobile-menu li > .submenu > li > a,
.mobile-category-menu li > .submenu > li > a {
  padding-left: 40px;
  color: var(--chesi-text);
  font-size: 12px;
}
.mobile-menu span.dropdown-btn,
.mobile-category-menu span.dropdown-btn {
  position: absolute;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  border-left: 1px solid var(--chesi-border);
  font-size: 11px;
}
.mobile-menu li.open > a > .dropdown-btn,
.mobile-menu span.dropdown-btn:hover,
.mobile-category-menu li.open > a > .dropdown-btn,
.mobile-category-menu span.dropdown-btn:hover {
  background-color: var(--chesi-gray-soft);
  color: var(--chesi-dark);
}
.mobile-menu li > a > .dropdown-btn:before,
.mobile-category-menu li > a > .dropdown-btn:before {
  transform: rotate(180deg);
}
.mobile-menu li.open > a > .dropdown-btn:before,
.mobile-category-menu li.open > a > .dropdown-btn:before {
  transform: rotate(0deg);
}
body.admin-bar.scroll-start .chesi-header-mobile.sticky-start {
  top: 32px;
}
@media (max-width: 1024px) {
  .chesi-header-mobile {
    display: flex;
    flex-wrap: wrap;
    padding: 20px 0px;
    gap: 20px;
  }

  .chesi-header-mobile .chesi-ajax-product-search {
    max-width: 100%;
  }

  .chesi-header-mobile.search-before {
    flex-direction: column-reverse;
    align-items: stretch;
  }

  .chesi-header-mobile.sticky-ready {
    position: fixed;
    top: -100%;
    left: 0;
    opacity: 0;
    z-index: 999;
  }

  .chesi-header-mobile.sticky-ready.sticky-start {
    top: 0;
    opacity: 1;
  }

  .chesi-header-mobile-fixed.sticky-ready {
    display: block;
  }

  .chesi-header .hidden-mobile {
    display: none;
  }
}
@media (max-width: 782px) {
  .admin-bar .chesi-mobile-menu .panel-close {
    top: 0;
    right: -40px;
  }
}

@media (max-width: 480px) {
  .admin-bar .chesi-header-mobile.sticky-ready {
    top: -100%;
    opacity: 0;
  }
  body.admin-bar.scroll-start .chesi-header-mobile.sticky-ready.sticky-start {
    top: 0px;
    opacity: 1;
  }
  .chesi-mobile-menu {
    max-width: calc(100% - 50px);
  }
}
.header-notices.top-action-btn.has-panel > i,
.header-notices.top-action-btn.has-panel > a > i {
  font-size: 24px;
  cursor: pointer;
}
.mobile-menu-tabs.tab-reverse {
  flex-direction: row-reverse;
}
.chesi-category-menu .submenu > li.has-template {
  position: static;
}
.chesi-category-menu li.has-template > .submenu {
  display: none;
}
.chesi-category-menu ul.header-cat-template {
  position: absolute;
  width: 100vw;
  max-width: calc(1430px - 270px);
  height: auto;
  padding: 0;
  background-color: var(--chesi-light);
  color: var(--chesi-dark);
  border-radius: 5px;
  border: none;
  opacity: 0;
  visibility: hidden;
  left: 100%;
  top: 0;
  z-index: 99;
  -webkit-box-shadow: 0 15px 30px -4px rgb(0 0 0 / 9%);
  -moz-box-shadow: 0 15px 30px -4px rgba(0, 0, 0, 0.09);
  box-shadow: -4px -2px 30px 2px rgb(0 0 0 / 17%);
}
.chesi-category-menu li.has-template:hover > .header-cat-template {
  opacity: 1;
  visibility: visible;
}
.chesi-mobile-menu .mega-container.has-el-template {
  display: none;
}
.mobile-category-menu ul.header-cat-template {
  display: none;
  padding: 15px;
}
.mobile-category-menu .has-template.hidden-on-mobile ul.header-cat-template {
  display: none !important;
}
.cats--found ul.header-cat-template {
  display: none;
}
.header-with-border {
  border-bottom: 0.1px solid #e2e1e1; /* Adjust the color and size as needed */
}

.icon-small-black {
  font-size: 5px;
  color: black;
}
