/* promotion top bar */
.promotion-bar .inner {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
    min-height: 30px;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    color: #fff;
    transition: height 0.5s, padding 0.5s;
    background: none;
    position: relative;
    background: #E71E23;
}
#promotionBar.closed {
  height: 0;
  padding: 0;
  overflow: hidden;
}
.promotion-bar .panel-close {
    position: absolute;
    right: 15px;
    top: 6px;
    width: 20px;
    height: 20px;
    border-width: 5px;
    background-color: transparent;
}
.promotion-bar.grad-anim-1 {
    background: linear-gradient(270deg, #000fe8, #e80027);
}
.grad-anim-1 {
    background-size: 400% 400%;
    -webkit-animation: grad-anim-1 22s ease infinite;
    -moz-animation: grad-anim-1 22s ease infinite;
    animation: grad-anim-1 22s ease infinite;
}