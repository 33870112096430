.widget-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Adjust spacing between widgets */
    justify-content: center;
    padding: 10px;
  }
  
  .widget {
    width: 230px; /* Adjust width of each widget */
    background-color: #fff; /* Adjust background color */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    text-align: center;
  }
  
  .widget-title {
    font-size: 18px; /* Adjust font size */
    margin-bottom: 10px; /* Space below title */
  }
  
  .widget-description {
    font-size: 14px; /* Adjust font size */
    margin-bottom: 10px; /* Space below description */
  }
  
  .widget-link {
    color: #007bff; /* Link color */
    text-decoration: none;
    font-size: 14px; /* Adjust font size */
    margin-bottom: 10px; /* Space below link */
  }
  
  .widget-link:hover {
    text-decoration: underline;
  }
  
  .widget-image {
    width: 100%; /* Makes image fit the widget width */
    height: auto; /* Maintain aspect ratio */
  }
  