.categories-container {
  display: flex;
  justify-content: space-between;
  padding: 20px;
  align-items: center;
}

.categories-sidebar {
  width: 250px;
  background-color: #f5f5f5;
  padding: 20px;
}

.category-list {
  flex: 1;
  margin-left: 20px;
}

.categories-title {
  text-align: left;
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: normal;
}

.categories-banner {
  width: 100%;
  max-width: 1015px;
  height: 270px;
  display: block;
  margin: 0 auto 20px auto;
}

.subcategory-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
  gap: 25px;
}

.subcategory-item-container {
  cursor: pointer;
}

.subcategories-item {
  width: calc(16.66% - 20px); /* 6 items per row */
  margin-bottom: 20px;
  text-align: center;
}

.subcategory-image {
  width: 80%;
  height: 80%;
  object-fit: cover;
}

.subcategory-name {
  font-size: 14px;
  font-weight: bold;
  margin-top: 5px;
}

.category-item-rounded {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  background-color: #eaeaea;
  text-align: center;
  margin: 0 auto;
  font-size: 14px;
  font-weight: bold;
}

.rounded-item-image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f0f0f0; /* Fallback background color */
  display: flex;
  align-items: center;
  justify-content: center;
}

.rounded-item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.rounded-item-title {
  margin-top: 8px;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  text-align: center;
}

@media (max-width: 768px) {
  .subcategory-grid {
    margin: 0 auto;
    gap: 0px;
    /* margin-left: 100px; */
  }

  /* .category-item-rounded {
    width: 100px;
    height: 100px;
  } */
  .subcategories-item {
    /* width: calc(16.66% - 20px); */
    margin-bottom: 20px;
    text-align: center;
  }

  .categories-sidebar {
    display: none;
  }
}
