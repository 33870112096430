.home-main-slider {
    display: flex;
    width: 100vw; /* Full viewport width */
    height: 100vh; /* Full viewport height */
    padding: 10px; /* Padding around the entire div */
    box-sizing: border-box; /* Includes padding in the width and height calculations */
  }
  
  .slider-column {
    flex: 1; /* Ensures all columns have equal width */
    margin: 0 5px; /* 5px total spacing between columns */
    border-radius: 10px; /* Rounded corners for each column */
    overflow: hidden; /* Ensures that content does not overflow the rounded corners */
  }
  
  .left {
    background-color: #f0f0f0;
    width: 20px;
  }
  
  .middle {
    border: 2px solid orange;
    border-radius: 10px; /* Rounded corners */
    text-align: center;
    padding: 20px;
    width: 300px;
  }
  
  .right {
    background-color: #f0f0f0; /* Optional: background color for visibility */
  }
  
  /* Ensure images fit well inside their columns */
  .slider-column img {
    width: 100%;
    height: auto;
    object-fit: cover; /* Keeps the aspect ratio of images */
  }

  .slider-column {
    width: 30%; /* Adjust width of slider columns */
  }
  
  .static-column {
    width: 300px; 
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border: 2px solid orange;
    border-radius: 10px;
  }
  
  .static-header {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .static-description {
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .countdown {
    display: flex;
    gap: 5px;
    margin-bottom: 15px;
  }
  
  .countdown-box {
    background-color: #DD3333;
    color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    font-size: 16px;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .static-image {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
  
  .static-bold-text {
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .static-price {
    color: #b32d2e;
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .static-stock {
    color: #4caf50;
    font-size: 14px;
  }
  