/* Default styles for large screens */
.header-middle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.mainlogo img {
  height: 50px;
  padding: 0;
}

.search-container {
  display: flex;
  flex: 1;
  margin: 0 20px;
}

.search-input {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px 0 0 4px;
}

.search-dropdown {
  padding: 8px;
  border: 1px solid #ddd;
  border-left: none;
  width: 10%;
}

.search-button {
  padding: 8px;
  background-color: #ee1925;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  color: #fff;
}

.search-icon {
  fill: #fff;
}

.contact-info {
  display: flex;
  align-items: center;
  margin-left: 1%;
  margin-right: 2%;
}

.whatsapp-icon {
  width: 24px;
  height: 24px;
  background-size: contain;
  margin-right: 10px;
}

.contact-text {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 10px;
}

.contact-call {
  font-size: 12px;
}

.contact-number {
  font-size: 14px;
  font-weight: bold;
}

.cart-delete-button {
  color: red;
}

.cart-delete-button:hover {
  color: brown;
}

.icons {
  display: flex;
  align-items: center;
  gap: 15px;
}

.icons i {
  width: 24px;
  height: 24px;
  background-size: contain;
  cursor: pointer;
}

.icons > *:hover {
  color: #ee1926;
}

.cart-items {
  max-height: 200px;
  overflow-y: auto;
  padding: 5px;
}

.similar-product-card .cart-image {
  width: 40px !important;
  height: 40px !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
}

.cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 0;
  max-width: 360px;
}

.cart-item-info {
  display: flex;
  align-items: center;
  gap: 10px;
}
.cart-lowerdiv {
  display: flex;
  justify-content: space-between;
}

.middle-cart-section {
  display: flex;
  gap: 5px;
  align-items: center;
}

.cart-price {
  color: red;
}

.cart-item-image img {
  width: 30px;
  height: 30px;
  border-radius: 4px;
  object-fit: cover;
}

.upper-info {
  display: flex;
  align-items: start;
  gap: 5px;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 5px;
}

.cart-item-title {
  font-weight: bold;
  font-size: 10px;
  color: black;
}

.cartImg {
  width: 50px;
  height: 50px;
}

.cart-item-price {
  color: red;
  font-size: 10px;
}

.cart-item-delete {
  cursor: pointer;
  color: #f00;
  font-size: 16px;
}

.delete-icon {
  color: red;
  cursor: pointer;
}

.delete-icon:hover {
  color: brown;
}

.horizontal {
  height: 3px;
  color: gray;
  width: 100%;
}

.cart-divider {
  border-top: 1px solid #ccc;
  margin: 5px 0;
}

.cart-subtotal {
  font-weight: bold;
  font-size: 10px;
  text-align: right;
  margin-bottom: 5px;
}

.cart-buttons {
  display: flex;
  justify-content: space-between;
}

.cart-button {
  padding: 5px 8px;
  font-size: 10px;
  background-color: #ee1926;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
  margin-right: 3px;
}

.checkout-button {
  background-color: #007bff;
  border: #007bff;
  border-radius: 10px;
  width: 100px;
  height: 50px;
  color: white;
}

.checkout-button:hover {
  background-color: #0069d9;
}

.cart-button:last-child {
  margin-right: 0;
}

/* Responsive styles for smaller screens */
@media (max-width: 768px) {
  .header-middle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow flex items to wrap */
    padding: 10px;
  }

  .mainlogo {
    order: 1; /* Keep the logo on top */
    flex: 1; /* Allow the logo to take full width */
    display: flex;
    justify-content: flex-start;
  }

  .icons {
    order: 2; /* Position icons next to the logo */
    margin-left: auto; /* Push icons to the right */
  }

  .search-container {
    order: 3; /* Move search below the logo and icons */
    width: 70%; /* Full width for search */
    margin: 10px 0;
  }

  .search-input,
  .search-dropdown,
  .search-button {
    padding: 3px;
    font-size: 14px;
  }

  .contact-info {
    margin: 5px 0;
    order: 4; /* Move contact info to the bottom */
    display: flex;
    align-items: center;
    font-size: 10px; /* Reduce overall font size for mobile */
  }

  .icons i {
    width: 20px;
    height: 20px;
  }

  .cart-item {
    max-width: 100%;
  }

  .cart-item-image img {
    width: 24px;
    height: 24px;
  }

  .cart-item-title,
  .cart-item-price,
  .cart-item-delete {
    font-size: 8px;
  }

  .cart-button {
    font-size: 8px;
    padding: 4px 6px;
  }

  .whatsapp-icon {
    width: 20px; /* Smaller icon size for mobile */
    height: 20px;
    margin-right: 5px; /* Reduce margin for better spacing */
  }

  .contact-text {
    margin-left: 5px; /* Reduced margin for compact layout */
  }

  .contact-call {
    font-size: 10px; /* Smaller font size for mobile */
  }

  .contact-number {
    font-size: 12px; /* Smaller font size for mobile */
    font-weight: bold;
  }
}
